import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { reSendOtp, setError, verifyOtp } from 'store/user/userSlice';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

import {
  Form,
  MobileAuthFormSubheader,
  StyledAuthTextField,
  StyledErrorMessage,
} from 'pages/AuthPage/AuthPage.styles';
import Link from 'atoms/Link/Link';
import { palette } from 'utils/styles/variables';
import { formatMS } from 'utils/helpers/dates';
import { neutrals } from 'utils/styles/color';

const initialState = { value: '', error: '' };

const VerificationForm = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useBaoDispatch();
  const {
    value: user,
    error: reduxError,
    loading,
  } = useBaoSelector((state) => state.user);

  const timeoutRef = useRef<NodeJS.Timer>();

  const [otp, setOtp] = useState<{ value: string; error: string }>(
    initialState
  );
  const [resendInfo, setResendInfo] = useState<{
    timer: number | null;
    isResent: boolean;
  }>({
    timer: null, // 3 * 60 * 1000,
    isResent: true,
  });
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const response = await dispatch(verifyOtp({ otp: otp.value }));

      if (response.type.indexOf('rejected') !== -1) {
        return null;
      }

      const redirectPath = searchParams.get('redirect');

      return navigate(redirectPath || '/dashboard');
    },
    [searchParams, dispatch, otp]
  );

  const handleChange = useCallback((event) => {
    const { value } = event.target;

    if (value && !/^[0-9\b]+$/.test(value)) return;

    if (value.length > 6) return;

    setOtp((prev) => ({ ...prev, value }));
  }, []);

  const handleResendCode = useCallback(async () => {
    await dispatch(reSendOtp());

    timeoutRef.current = setInterval(() => {
      const resendTime = checkResendTime();

      if (resendTime !== 0) {
        setResendInfo((prev) => ({ ...prev, timer: resendTime }));
      } else {
        setResendInfo((prev) => ({ ...prev, isResent: false }));
        localStorage.removeItem('otpExpireAt');
        clearInterval(timeoutRef.current);
      }
    }, 1000);

    setResendInfo({ isResent: true, timer: checkResendTime() });
  }, [dispatch]);

  useEffect(() => {
    if (!user?.credentials) navigate('/signin');

    return () => {
      dispatch(setError(''));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      clearTimeout(timeoutRef.current);

      if (reduxError) {
        if (reduxError.indexOf('temporary blocked') >= 0) {
          setIsBlocked(true);
          setOtp(initialState);
        } else {
          setOtp((prev) => ({ ...prev, error: reduxError }));
        }
      }

      const blockedTime = checkIfUserBlocked(); // returns ms until blocked time.

      if (blockedTime) {
        setIsBlocked(true);
        timeoutRef.current = setInterval(() => {
          if (!checkIfUserBlocked()) {
            setIsBlocked(false);
            setResendInfo({ isResent: false, timer: 0 });
            clearTimeout(timeoutRef.current);
            localStorage.removeItem('blocked');
          }
        }, 1000);
      } else {
        setIsBlocked(false);
        localStorage.removeItem('blocked');

        if (checkResendTime()) {
          timeoutRef.current = setInterval(() => {
            const resendTime = checkResendTime();

            if (resendTime !== 0) {
              setResendInfo((prev) => ({ ...prev, timer: resendTime }));
            } else {
              setResendInfo((prev) => ({ ...prev, isResent: false }));
              clearInterval(timeoutRef.current);
              localStorage.removeItem('otpExpireAt');
            }
          }, 1000);
        } else {
          setResendInfo({ isResent: false, timer: 0 });
          clearTimeout(timeoutRef.current);
        }
      }

      return () => clearTimeout(timeoutRef.current);
    },
    // eslint-disable-next-line
    [reduxError]
  );

  function checkIfUserBlocked(): number {
    const blockedInfo = JSON.parse(localStorage.getItem('blocked') || '{}');

    if (!blockedInfo.message) return 0;

    const diff = +blockedInfo.timestamp - +new Date() / 1000;

    dispatch(setError(blockedInfo.message));

    return diff > 0 ? diff : 0;
  }

  function checkResendTime(): number {
    const loggedInAt = Number(localStorage.getItem('otpExpireAt') || '0');

    if (!loggedInAt) return 0;

    const diff = loggedInAt - +new Date();

    return diff > 0 ? diff : 0;
  }

  if (width < 600) {
    return (
      <>
        {isBlocked && reduxError ? (
          <MobileAuthFormSubheader sx={{ color: '#EF4444 !important' }}>
            {reduxError}
          </MobileAuthFormSubheader>
        ) : (
          <MobileAuthFormSubheader>
            Your account is protected with two-factor authentication. We’ve sent
            you an email with the code. Please enter the code below.
          </MobileAuthFormSubheader>
        )}
        <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
          <StyledAuthTextField
            fullWidth
            variant="outlined"
            label="2FA Code"
            margin="normal"
            type="text"
            name="otp"
            value={otp.value}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            sx={{ mt: 1, paddingBottom: '10px' }}
            InputLabelProps={{ shrink: true }}
            error={!!otp.error}
            helperText={otp.error}
            disabled={isBlocked}
          />
          {resendInfo.isResent && !isBlocked ? (
            <Typography
              fontSize="0.875rem"
              color="#575D66"
              variant="body2"
              m="10px 0 34px"
              sx={{ fontWeight: 400, color: '#575D66', fontSize: '1rem' }}
            >
              {resendInfo.timer ? (
                <>
                  You will be able to resend a code again in{' '}
                  {formatMS(resendInfo.timer)}
                </>
              ) : (
                <span />
              )}
              .
            </Typography>
          ) : (
            <Button
              variant="text"
              type="button"
              sx={{
                textDecorationLine: 'underline',
                fontWeight: 400,
                padding: 0,
                textAlign: 'right',
                fontSize: '0.75rem !important',
                lineHeight: '18px',
                margin: '5px 0 34px auto',
                display: 'block',
                ':disabled': {
                  color: '#7B828C',
                },
              }}
              onClick={handleResendCode}
              disabled={isBlocked}
            >
              Resend a code
            </Button>
          )}
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{
              margin: '5px 0 20px',
              ':disabled': {
                backgroundColor: '#A1A8B2',
                color: '#FFFFFF',
              },
            }}
            fullWidth
            loading={loading}
            disabled={isBlocked}
          >
            Verify
          </LoadingButton>
          <Stack alignItems="end">
            <Link
              $fontWeight={500}
              $fontSize="0.75rem"
              $lineHeight="18px"
              to="/signin"
              $color={palette.primary.main}
            >
              Back to login page
            </Link>
          </Stack>
        </Form>
      </>
    );
  }

  return (
    <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
      <Stack gap={0} height="86px">
        <StyledAuthTextField
          fullWidth
          variant="outlined"
          label="2FA Code"
          margin="normal"
          type="text"
          name="otp"
          value={otp.value}
          inputProps={{ min: 0, style: { textAlign: 'center' } }}
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2, mb: 0 }}
          error={!!otp.error}
          helperText={otp.error}
          disabled={isBlocked}
        />
        <StyledErrorMessage>
          {isBlocked && reduxError ? reduxError : ''}
        </StyledErrorMessage>
      </Stack>
      <Stack gap="16px" alignItems="end">
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={loading}
          disabled={isBlocked}
          sx={{
            lineHeight: '20px',
            textTransform: 'uppercase',
            borderRadius: '3px',
            ':disabled': {
              backgroundColor: '#A1A8B2',
              color: '#FFFFFF',
            },
          }}
        >
          Verify
        </LoadingButton>
        {resendInfo.isResent && !isBlocked ? (
          <Typography
            variant="body2"
            lineHeight="18px"
            fontSize="0.75rem"
            fontWeight={400}
            color={neutrals[500]}
            textAlign="right"
          >
            {resendInfo.timer && (
              <>
                You will be able to resend a code again in{' '}
                {formatMS(resendInfo.timer)} seconds.
              </>
            )}
          </Typography>
        ) : (
          <Button
            variant="text"
            type="button"
            sx={{
              padding: 0,
              fontWeight: 500,
              lineHeight: '18px',
              fontSize: '0.75rem !important',
              textDecorationLine: 'underline',
              ':disabled': {
                color: '#7B828C',
              },
              ':hover': {
                background: 'transparent',
                textDecoration: 'underline',
              },
            }}
            onClick={handleResendCode}
            disabled={isBlocked}
          >
            Resend a code
          </Button>
        )}
        <Link
          $fontWeight={500}
          $fontSize="0.75rem"
          $lineHeight="18px"
          to="/signin"
          $color={palette.primary.main}
        >
          Back to log in page
        </Link>
      </Stack>
    </Form>
  );
};

export default VerificationForm;
