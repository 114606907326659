/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Box } from '@mui/material';
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
  Cell,
} from 'recharts';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { StyledCardHeader } from './InvoiceChart.styles';
import { formatLargeNumber } from 'utils/helpers/currencyFormatter';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { MonthDetails } from 'organisms/InvoiceChart/components/MonthDetails';
import { neutrals } from 'utils/styles/color';

type InvoiceChartProps = { companyHsId?: string | null; isDashboard?: boolean };

export interface InvoiceChartState {
  total_payment: number;
  total_outstanding: number;
  month: string;
}

const InvoiceChart = ({ companyHsId, isDashboard }: InvoiceChartProps) => {
  const [focusedBar, setFocusedBar] = useState<InvoiceChartState | null>(null);
  const [invoices, setInvoices] = useState<Array<InvoiceChartState>>([]);
  const [currency, setCurrency] = useState<
    keyof typeof currencyIcon | undefined
  >();

  useEffect(() => {
    if (!companyHsId) return;

    // todo add catch block
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/payment-and-outstanding`,
        {
          params: {
            ...(companyHsId && { company_hs_id: companyHsId }),
            num_of_months: 9,
          },
        }
      )
      .then(({ data }) => {
        setInvoices(data.items);
      });

    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company/${companyHsId}`)
      .then((response) => {
        const { data } = response;
        setCurrency(data.currency);
      });
  }, [companyHsId]);

  const [spacing, setSpacing] = useState<number>(0);

  return (
    <Card
      sx={{ height: isDashboard ? '300px' : '255px', position: 'relative' }}
    >
      <StyledCardHeader
        title="Invoice totals by months"
        avatar={<CardAvatar />}
      />
      <MonthDetails details={focusedBar} currency={currency} />
      <CardContent>
        <Box height={isDashboard ? 195 : 150}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={invoices}
              onMouseMove={(state: {
                activeTooltipIndex?: number;
                isTooltipActive?: boolean;
              }) => {
                const { isTooltipActive, activeTooltipIndex } = state;
                if (isTooltipActive) {
                  setFocusedBar(invoices[activeTooltipIndex as number] || null);
                } else {
                  setFocusedBar(null);
                }
              }}
              onMouseLeave={() => setFocusedBar(null)}
            >
              <CartesianGrid vertical={false} />
              <Bar
                stackId="a"
                dataKey="total_payment"
                fill="#F5BABA"
                barSize={40}
                radius={[5, 5, 0, 0]}
                activeBar={{ fill: '#F5BABA', strokeWidth: 2 }}
              >
                {invoices.map((entry) => (
                  <Cell
                    key={`cell-${entry.month}`}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    radius={entry.total_outstanding ? 0 : [5, 5, 0, 0]}
                  />
                ))}
              </Bar>
              <Bar
                stackId="a"
                dataKey="total_outstanding"
                fill="#FEDEDE"
                barSize={40}
                radius={[5, 5, 0, 0]}
                activeBar={{ fill: '#FEDEDE', strokeWidth: 2 }}
              />
              <XAxis
                dataKey="month"
                tick={{ fontSize: 12, stroke: '#505459', strokeWidth: 0.5 }}
                tickFormatter={(tick) => tick.substring(0, 3)}
              />
              <YAxis
                width={spacing}
                tick={{
                  fontSize: 12,
                  stroke: neutrals[700],
                  strokeWidth: 0,
                  color: neutrals[700],
                }}
                tickFormatter={(value) => {
                  // const displayValue = value
                  //   ? currencyFormatter(Number(value), currency)
                  //   : '0';
                  // const yAxisSpace = getYAxisSpace(value, displayValue);
                  // setSpacing((prev) => (prev > yAxisSpace ? prev : yAxisSpace));
                  // return displayValue;
                  const displayValue = formatLargeNumber(
                    Number(value),
                    currency || 'USD'
                  );
                  const yAxisSpace = 27 + displayValue.length * 3;
                  setSpacing((prev) => (prev > yAxisSpace ? prev : yAxisSpace));

                  return displayValue;
                }}
              />
              <Tooltip
                cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                content={() => ''}
                labelFormatter={() => ''}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InvoiceChart;
