import React, { FC, useCallback } from 'react';

import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Report } from 'organisms/CloudOpsReportAdmin/types';

import axios from 'axios';
import { months } from 'utils/constants/common';

export const ApproveReport: FC<{
  report: Report;
  onClose: () => void;
  onAfterClose: () => void;
}> = ({ report, onClose, onAfterClose }) => {
  const onSubmit = useCallback(async () => {
    await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/project/approve-report`,
      {
        params: {
          id: report.id,
        },
      }
    );

    onAfterClose();
  }, [report]);

  const month = report.month ? months[report.month - 1] : '';

  return (
    <Modal
      open
      maxWidth="sm"
      title="Invoice creation confirmation"
      subtitle={`Please confirm invoice creation for the ${report.project.name} ${month} period.`}
      onClose={onClose}
      sx={{ zIndex: 33333 }}
    >
      <ModalActions>
        <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
          Cancel
        </ModalCloseButton>
        <ModalSubmitButton
          autoFocus
          size="medium"
          variant="contained"
          onClick={onSubmit}
        >
          Confirm
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
};
