import { Stack } from '@mui/material';
import styled from 'styled-components';
import { neutrals } from 'utils/styles/color';

export const StyledActionsBlock = styled(Stack)`
  && {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      height: 22px;

      & > span {
        font-family: Poppins, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0%;
        color: ${neutrals[500]};
      }

      & > a {
        width: 112px;
        text-align: right;
      }
    }
  }
`;

export const StyledContactText = styled.span`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: right;
  color: ${neutrals[500]};
  a {
    font-weight: 500;
    text-decoration: none;
  }
`;
