import React, { FC, useState, useCallback } from 'react';
import Modal from 'molecules/Modal';
import {
  CloudOpsProjectsType,
  QuickbooksProjectType,
} from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Alert, Stack } from '@mui/material';
import { QuickbooksProjectAutoComplete } from 'molecules/ProjectAutoComplete/QuickbooksProjectAutoComplete';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';
import axios from 'axios';
import { log } from 'utils/helpers/logger';

const ZohoProjectSyncDialog: FC<{
  project: CloudOpsProjectsType;
  companyHsId?: string;
  onSync: () => void;
  onClose: () => void;
}> = ({ companyHsId, onClose, onSync, project }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] =
    useState<CloudOpsProjectsType>();

  const handleCloudOpsProjectChange = useCallback(async (selected) => {
    setSubmitted(false);

    setLoading(true);
    const cloudOpsProjectData = await getCloudOpsProjectData(selected);
    setLoading(false);

    setCloudOpsProjectSelected({ ...selected, ...cloudOpsProjectData });
  }, []);

  const onSubmit = async () => {
    setSubmitted(true);
    if (!project || !cloudOpsProjectSelected) return;

    try {
      handleSyncProjects();
    } catch (error) {
      if (error.response.status === 404) {
        handleSyncProjects();
      }
    }
  };

  const handleQuickbooksProjectChange = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('This method will never reached!');
  }, []);

  async function handleSyncProjects() {
    try {
      const { status } = await syncQuickbooksWithStream();

      if (typeof onSync === 'function' && status) {
        onSync();
        return;
      }

      if (status) {
        onClose();
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  }

  async function syncQuickbooksWithStream() {
    if (
      !cloudOpsProjectSelected?.id_str ||
      !companyHsId ||
      !project?.quickbook_project_id
    )
      return { status: false, isNonExist: false };

    try {
      const existingPrj = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${cloudOpsProjectSelected.id_str}`
      );

      // If project has `quickbook_project_id` value, then it means
      // project already synchronized.
      if (existingPrj.data.quickbook_project_id) {
        setAlert({
          error: true,
          message: 'Project already synced, please select another project!',
        });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
        return { status: false, isNonExist: false };
      }

      await axios.put(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${cloudOpsProjectSelected.id_str}`,
        {
          ...project,
          ...cloudOpsProjectSelected,
        }
      );

      return { status: true, isNonExist: false };
    } catch (error) {
      if (error.response.status !== 404) {
        setAlert({ error: true, message: error.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
        return { status: false, isNonExist: false };
      }

      // eslint-disable-next-line no-return-await
      return await createNewProject();
    }
  }

  async function createNewProject() {
    if (!cloudOpsProjectSelected?.id)
      return { status: false, isNonExist: false };

    try {
      const { id_str, type: projectType } = cloudOpsProjectSelected;
      const { quickbook_project_id } = project || {};

      await axios.post(`${process.env.REACT_APP_USER_SERVICE}/projects`, {
        company_hs_id: Number(companyHsId),
        project_id: typeof cloudOpsProjectSelected === 'object' ? id_str : 0,
        quickbook_project_id,
        type: projectType || 'NOT_CO',
      });

      return { status: true, isNonExist: true };
    } catch (err) {
      setAlert({ error: true, message: err.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);

      return { status: false, isNonExist: false };
    }
  }

  async function getCloudOpsProjectData(selectedProject: CloudOpsProjectsType) {
    if (!selectedProject?.id) return {};

    try {
      const cloudOpsProject = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${selectedProject.id_str}`
      );
      return cloudOpsProject.data;
    } catch (e) {
      log(e);
      return {};
    }
  }

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      title="Sync with Zoho Projects"
      subtitle="QuickBooks projects automatically detected by Zazmic-Connect can be synced with project listed in Zoho Projects that has not yet been synced with a QuickBooks project, ensuring full integration."
      onClose={onClose}
      transitionDuration={0}
    >
      <>
        <ModalContent sx={{ overflowY: 'hidden' }}>
          {alert.message && (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          )}
          <Stack gap="22px" pt="5px">
            <QuickbooksProjectAutoComplete
              companyHsId={String(companyHsId)}
              submitted={submitted}
              project={project as QuickbooksProjectType}
              disabled
              onChange={handleQuickbooksProjectChange}
            />
            <ZStreamProjectAutoComplete
              disabled={false}
              project={project}
              submitted={submitted}
              onChange={handleCloudOpsProjectChange}
              loading={loading}
            />
          </Stack>
        </ModalContent>
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Sync
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default ZohoProjectSyncDialog;
