import React, { FC, useEffect, useState } from 'react';
import Modal from 'molecules/Modal';
import { ModalContent, ModalSubtitleText } from 'molecules/Modal/Modal.styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Stack } from '@mui/material';
import { StyledLoadingButton } from './InvoicePreview.styles';

import axios from 'axios';
import { getFilenameFromHeader } from 'organisms/InvoicesTable/helpers';
import loaderGif from 'assets/images/loaderGif.gif';

interface InvoicePreviewProps {
  id: string;
  onClose: () => void;
}

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const InvoicePreview: FC<InvoicePreviewProps> = ({ id, onClose }) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [pages, setPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(numPages);
  };

  const onDownload = () => {
    if (!pdfUrl || !fileName) return;

    const link = document.createElement('a');
    link.href = pdfUrl; // response.request?.responseURL;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const renderSubtitle = () => (
    <Stack gap="16px" height="58px">
      <ModalSubtitleText>
        {pages ? `${pages} ${pages > 1 ? 'pages' : 'page'}` : ''}
      </ModalSubtitleText>
      {pdfUrl && (
        <StyledLoadingButton onClick={onDownload}>
          Download PDF
        </StyledLoadingButton>
      )}
    </Stack>
  );

  useEffect(() => {
    fetchPdf();
  }, [id]);

  useEffect(() => () => URL.revokeObjectURL(pdfUrl || ''), []);

  async function fetchPdf() {
    if (!id) {
      setPdfUrl(null);
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_REPORT_SERVICE}/invoices/report?ids=${id}`,
      { responseType: 'blob' }
    );

    const url = URL.createObjectURL(response.data);
    const filename = getFilenameFromHeader(response);

    setPdfUrl(url);
    setFileName(filename);
  }

  return (
    <Modal
      open
      maxWidth="md"
      title="Invoice preview"
      subtitle={renderSubtitle()}
      onClose={onClose}
      sx={{
        zIndex: 33333,
        '.MuiPaper-root': {
          background: 'transparent',
          boxShadow: 'none',
          borderRadius: 0,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          '& > div:first-of-type': {
            borderRadius: '4px',
          },
        },
        '.modal-title-block': {
          background: '#FFFFFF',
        },
      }}
    >
      <ModalContent
        sx={{
          p: '0px !important',
          minHeight: '80px',
          '.react-pdf__Page__canvas': {
            width: '600px !important',
          },
          '.react-pdf__Page.pdf-page': {
            mb: '10px',
          },
        }}
      >
        {pdfUrl ? (
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Box sx={{ minHeight: '80px' }} />}
          >
            {pages ? (
              Array.from({ length: pages }, (_, index) => (
                <Page
                  key={index + 1}
                  pageNumber={index + 1}
                  className="pdf-page"
                />
              ))
            ) : (
              <Box sx={{ minHeight: '80px' }} />
            )}
          </Document>
        ) : (
          <Box
            sx={{
              textAlign: 'center',
              padding: '40px 0',
              background: '#FFFFFF !important',
            }}
          >
            <img src={loaderGif} alt="loader" />
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default InvoicePreview;
