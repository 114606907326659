import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import { getBrowserConfig, httpLogger } from 'utils/helpers/logger';

export interface UserCompany {
  company_hs_id: number;
  name: string;
  is_main: boolean;
}

export type UserState = {
  companyHsId: number;
  email: string;
  name: string;
  surname: string;
  accessToken: string;
  refreshToken: string;
  userType: string;
  userId: string;
  id: string; // which one is valid
  credentials: boolean;
  is_main: boolean;
  companies: UserCompany[];
  isTemporaryPassword: boolean;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  lastLoggedTime?: string;
  lastResentInvite?: string;
};

// Define the initial state using that type
const initialState: ReduxState<UserState> = {
  value: {
    email: '',
    name: '',
    surname: '',
    accessToken: '',
    refreshToken: '',
    userType: '',
    userId: '',
    id: '',
    companyHsId: 0,
    credentials: false,
    is_main: false,
    companies: [],
    isTemporaryPassword: false,
  },
  loading: false,
  error: '',
};

const storeUserInfo = (user: Partial<UserState>) => {
  const data = mapKeys(user, (v, k) => camelCase(k)) as UserState;
  localStorage.setItem('user', JSON.stringify(data));
  sessionStorage.setItem('company', JSON.stringify(data.companies?.[0] || {}));
  return data;
};

const updateUserInfo = (user: Partial<UserState>) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const login = createAsyncThunk(
  'user/login',
  async (form: { email: string; password: string; rememberMe: boolean }) => {
    try {
      const payload = {
        email: form.email,
        password: form.password,
        remember_me: form.rememberMe,
      };
      httpLogger([
        getBrowserConfig(),
        `${form.email} accessing endpoint: GET ${process.env.REACT_APP_USER_SERVICE}/login-otp`,
      ]);
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/login`,
        payload
      );

      localStorage.setItem('token', response.data.token);
      localStorage.setItem(
        'otpExpireAt',
        `${+new Date() + (response.data.time_left || 3 * 60) * 1000}`
      );
      localStorage.removeItem('blocked');

      return storeUserInfo({ ...initialState.value, credentials: true });
    } catch (e) {
      localStorage.removeItem('otpExpireAt');
      if (e?.response?.status === 429) {
        localStorage.setItem('blocked', JSON.stringify(e.response.data));
        throw e.response.data.message;
      }
      throw e?.response?.data?.message || e.message;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'user/verifyOtp',
  async (form: { otp: string }) => {
    const token = localStorage.getItem('token') || '';

    if (!token) {
      throw new Error('Try to login again');
    }

    try {
      const payload = { otp: form.otp, token };
      httpLogger([
        getBrowserConfig(),
        `Someone accessing endpoint: GET ${process.env.REACT_APP_USER_SERVICE}/verify-otp`,
      ]);
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/verify-otp`,
        payload
      );
      httpLogger([
        `GET ${process.env.REACT_APP_USER_SERVICE}/verify-otp, ${
          response?.data?.email || ''
        } successfully logged in.`,
      ]);

      localStorage.removeItem('token');
      localStorage.removeItem('blocked');

      return storeUserInfo(response.data);
    } catch (e) {
      if (e.response.status === 429) {
        localStorage.setItem('blocked', JSON.stringify(e.response.data));
        throw e.response.data.message;
      }
      throw e.response.data.message;
    }
  }
);

export const reSendOtp = createAsyncThunk('user/reSendOtp', async () => {
  const token = localStorage.getItem('token') || '';

  if (!token) {
    throw new Error('Try to login again');
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICE}/resend-otp`,
      {
        token,
      }
    );
    localStorage.setItem(
      'otpExpireAt',
      `${+new Date() + (response.data.time_left || 3 * 60) * 1000}`
    );
  } catch (e) {
    throw e.response.data.message;
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    logout: () => {
      localStorage.removeItem('user');
      sessionStorage.removeItem('company');
      return initialState;
    },
    setUserState: (state, action: PayloadAction<AxiosResponse>) => {
      state.value = storeUserInfo(action.payload.data);
    },
    setUserData: (state, action: PayloadAction<UserState>) => {
      state.value = action.payload;
      updateUserInfo(action.payload);
    },
    autoLogin: () => {
      const user = localStorage.getItem('user');

      if (!user) return initialState;

      const { expiresAt } = JSON.parse(user) || {};

      if (expiresAt * 1000 < +new Date()) {
        // eslint-disable-next-line no-console
        console.info('INFO: Your token is expired. Please login again.');
        localStorage.removeItem('user');
        return initialState;
      }

      return { ...initialState, value: JSON.parse(user) };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(login.fulfilled, verifyOtp.fulfilled),
      (state, action) => {
        state.value = action.payload as UserState;
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(login.rejected, verifyOtp.rejected, reSendOtp.rejected),
      (state, action) => {
        state.error = action.error.message || '';
        state.loading = false;
      }
    );
    builder.addMatcher(isAnyOf(login.pending, verifyOtp.pending), (state) => {
      state.loading = true;
    });
  },
});

export const { setError, logout, setUserState, autoLogin, setUserData } =
  userSlice.actions;

export default userSlice.reducer;
