import React, { useEffect, useState } from 'react';

import { Row, MonthPickerWrapper, StyledMonth } from './styles';
import { Box, Typography } from '@mui/material';

import { monthsNumber } from './constants';
import { MonthPickerProps } from './types';
import dayjs, { Dayjs } from 'dayjs';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const MonthPicker: React.FC<MonthPickerProps> = ({
  value,
  onSelect,
  shouldDisableDate,
}) => {
  const [selected, setSelected] = useState<Date | null>(value);
  const [current, setCurrent] = useState<Dayjs>(value ? dayjs(value) : dayjs());

  useEffect(() => {
    if (value) {
      setSelected(value);
      setCurrent(dayjs(value));
    }
  }, [value]);

  const handleMonthClick = (date: Date) => {
    setSelected(date);
    onSelect(date);
  };

  const renderMonth = (year: number, month: number) => {
    const currentMonth = new Date(year, month, 1);
    const isSelected = dayjs(selected).isSame(dayjs(currentMonth), 'month');
    const isDisabled = shouldDisableDate
      ? shouldDisableDate(currentMonth)
      : false;

    const getVariants = () => {
      if (isSelected) return 'selected';
      return null;
    };

    return (
      <StyledMonth
        key={month}
        disabled={isDisabled}
        variant={getVariants() as string}
        onClick={isDisabled ? undefined : () => handleMonthClick(currentMonth)}
      >
        {new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
          currentMonth
        )}
      </StyledMonth>
    );
  };

  const renderYear = (year: number) => (
    <Row
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'center',
        columnGap: '23px',
      }}
    >
      {monthsNumber.map((month) => renderMonth(year, month))}
    </Row>
  );

  const handlePrevYear = () => {
    setCurrent((prev) => prev?.subtract(1, 'year'));
  };

  const handleNextYear = () => {
    setCurrent((prev) => prev?.add(1, 'year'));
  };

  return (
    <MonthPickerWrapper>
      <Box>
        <Box onClick={handlePrevYear}>
          <KeyboardArrowLeftIcon />
        </Box>
        <Typography component="span">{current.year()}</Typography>
        <Box onClick={handleNextYear}>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
      {renderYear(current.year())}
    </MonthPickerWrapper>
  );
};
