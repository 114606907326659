import axios, { AxiosResponse } from 'axios';
import { IFilterOption } from 'organisms/Filter/types';

export type filterApplyType = {
  date_from: string;
  date_to: string;
  contract_type: string;
  status: string;
};

type FilterOptions = {
  contracts: { id: string; name: string }[];
  statuses: { id: string; name: string }[];
};

export const fetchList = async (name: string) => {
  const response: AxiosResponse<FilterOptions> = await axios.get(
    `${process.env.REACT_APP_USER_SERVICE}/projects/reports`,
    {
      params: { limit: 1 },
    }
  );

  let key: 'contracts' | 'statuses' | '' = '';
  switch (name) {
    case 'contract_type':
      key = 'contracts';
      break;
    case 'status':
      key = 'statuses';
      break;
    default:
      break;
  }

  if (!key) return [];

  return (response.data?.[key] || []) as { id: string; name: string }[];
};

export const initialOptions: Array<IFilterOption> = [
  { code: 'date', title: 'Period', active: true },
  { code: 'contract_type', title: 'Contract type', fetchList },
  {
    code: 'status',
    title: 'Invoice details',
    fetchList,
  },
];
