import React, { FC, useCallback, useEffect, useState } from 'react';

import Modal from 'molecules/Modal';
import { ModalContent } from 'molecules/Modal/Modal.styles';
import TimeLogTable from '.';

import axios, { AxiosResponse } from 'axios';
import { months } from 'utils/constants/common';

import { Report } from 'organisms/CloudOpsReportAdmin/types';
import { Row } from 'organisms/InvoicesTable/types';

export const InvoiceTimeLogModal: FC<{
  invoice: Row;
  onClose: () => void;
}> = ({ invoice, onClose }) => {
  const [report, setReport] = useState<Report | null>(null);

  const handleLogsClose = useCallback(() => {
    setReport(null);
    onClose();
    document.body.setAttribute('id', '');
  }, []);

  useEffect(() => {
    (async () => {
      if (!invoice.invoiceProjectReportId) return;

      const response: AxiosResponse<{
        count: number;
        projects_reports: Report[];
      }> = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/reports`,
        {
          params: {
            report_id: invoice.invoiceProjectReportId,
          },
        }
      );

      setReport(response.data.projects_reports[0]);
    })();
  }, [invoice.invoiceProjectReportId]);

  if (!report) return <span />;

  return (
    <Modal
      open
      maxWidth="lg"
      title={`${
        report?.month ? months[report?.month - 1] : ''
      } time logs details`}
      subtitle={`This window provides comprehensive breakdown of time logs for the ${
        report?.month ? months[report?.month - 1] : ''
      }. The details include a list of users, their hourly rates, daily time logs with corresponding amounts, and the total logged hours and amount per user for the month. Additionally, you can download a CSV file containing this information for further analysis or record-keeping.`}
      onClose={handleLogsClose}
      sx={{ zIndex: 33333 }}
    >
      <ModalContent
        sx={{
          paddingBottom: '16px !important',
          maxHeight: '400px',
          overflow: 'hidden',
        }}
      >
        {report ? <TimeLogTable report={report} /> : '...'}
      </ModalContent>
    </Modal>
  );
};
