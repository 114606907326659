import styled from 'styled-components';
import { Box } from '@mui/material';

export const AlertContainer = styled.div`
  > .MuiAlert-standardSuccess {
    background-color: rgba(10, 141, 84, 0.22);
  }
`;

export const AlertContainer2 = styled(Box)`
  && {
    .MuiPaper-root {
      display: flex;
      justify-content: center;

      padding: 16px;
      border-radius: 0;

      width: auto !important;
      min-width: fit-content !important;
    }

    .MuiAlert-standardSuccess {
      background-color: ${({ theme }) => theme.palette.success[100]};
      color: ${({ theme }) => theme.palette.success[700]};
    }

    .MuiAlert-standardError {
      background-color: ${({ theme }) => theme.palette.error[200]};
      color: ${({ theme }) => theme.palette.error[450]};
    }

    .MuiAlert-message {
      text-align: justify;

      font-family: Poppins, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;

      padding: 0;
    }
  }
`;
