import React, { FC, useState, useCallback } from 'react';

import Table from 'molecules/Table';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import Link from 'atoms/Link/Link';
import Service from 'organisms/SalesReportTable/Service/Service';

import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { StyledInvoiceStatus } from 'organisms/SalesReportTable/SalesReportTable.styles';

import dayjs from 'dayjs';
import { snakeCase } from 'lodash-es';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';

import { Order } from 'organisms/InvoicesTable/types';
import { palette } from 'utils/styles/variables';

export interface ISalesReportService {
  amount: number;
  description: string;
  name: string;
  value: string;
}

export interface SalesReportRow {
  company_name: string;
  sale_rep_name: string;
  account_manager_name: string;
  close_date: string;
  invoice_date: string;
  invoice_number: string;
  invoice_amount: number;
  invoice_status: 'paid' | 'unpaid';
  invoice_qb_id: string;
  id: string;
  currency: string;
  currency_symbol: string;
  company_hs_id: string;
  sale_rep_email: string;
  account_manager_email: string;
  due_date: null | string;
  paid_date: null | string;
  service_description: string;
  service_reference: string;
  services: ISalesReportService[];
  total: number;
  total_tax_amount: number;
  discount_amount: number;
  subtotal: number;
  amount_due: number;
  paid_total: number;
  transaction_fee: number;
  link: string;
  payments_credits: number;
}

export type salesRepSortBy =
  | 'sale_rep_name'
  | 'account_manager_name'
  | 'company_name'
  | 'invoice_date'
  | 'close_date'
  | 'invoice_amount';

export const initialValue = { page: 1, limit: 20 };

const SalesReportTable: FC<{
  loading?: boolean;
  data: { deals: SalesReportRow[]; count: number };
  onInvoiceClick: (invoiceQbId: string) => void;
  onChange: (f: typeof initialValue, s?: string) => void;
}> = ({
  loading,
  data: { deals = [], count = 0 },
  onInvoiceClick,
  onChange,
}) => {
  const [activeRow, setActiveRow] = useState<number>();

  const [pagination, setPagination] = useState(initialValue);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<salesRepSortBy>('invoice_date');

  const handleRequestSort = useCallback(
    async (event: React.MouseEvent<unknown>, property: salesRepSortBy) => {
      const isAsc = orderBy === property && order === 'asc';
      const nextState = { ...pagination, page: 1 };

      onChange(
        nextState,
        `sort[${snakeCase(property)}]=${isAsc ? 'desc' : 'asc'}`
      );

      setPagination(nextState);
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangeRowsPerPage = useCallback(
    async (option: number) => {
      const nextState = { limit: option, page: 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [orderBy, order, pagination, onChange]
  );

  const handleRowClick = useCallback(
    (event, row) => {
      if (window.getSelection()?.type === 'Range') return;

      setActiveRow(activeRow === row.id ? 0 : row.id);
    },
    [activeRow]
  );

  const rowDetailsRenderer = useCallback(
    (row: SalesReportRow) => <Service row={row} />,
    []
  );

  function setRowKey(row: SalesReportRow, index: number) {
    return { ...row, id: `${row.invoice_date}-${index}` };
  }

  const columns = [
    {
      key: 'sale_rep_name',
      label: 'Deal owner',
      sortable: true,
      width: '20%',
      render: ({ sale_rep_name }: SalesReportRow) => (
        <StyledCellText>{sale_rep_name}</StyledCellText>
      ),
    },
    {
      key: 'account_manager_name',
      label: 'Company owner',
      sortable: true,
      width: '20%',
      render: ({ account_manager_name }: SalesReportRow) => (
        <StyledCellText>{account_manager_name}</StyledCellText>
      ),
    },
    {
      key: 'company_name',
      label: 'Company name',
      sortable: true,
      width: '20%',
      render: ({ company_name, company_hs_id }: SalesReportRow) => (
        <Link to={`/company/${company_hs_id}`} $textDecoration="none">
          <StyledCellText $textType="link">{company_name}</StyledCellText>
        </Link>
      ),
    },
    {
      key: 'invoice_date',
      label: 'Invoice Date',
      greyText: 'Invoice #',
      sortable: true,
      width: '10%',
      render: ({
        invoice_number,
        invoice_date,
        invoice_qb_id,
      }: SalesReportRow) => (
        <StyledCellContainer $vStack>
          <StyledCellText>
            {invoice_date
              ? dayjs(dayjs(invoice_date)).format('MMM DD, YYYY')
              : ''}
          </StyledCellText>
          <StyledCellText
            $textType="grey"
            $color={invoice_qb_id ? palette.primary.main : undefined}
            sx={{
              display: 'inline',
              width: 'fit-content',
              // Only apply if invoice_qb_id is exists
              ...(invoice_qb_id
                ? {
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }
                : {}),
            }}
            onClick={(event) => {
              event?.preventDefault();
              event?.stopPropagation();

              if (invoice_qb_id) {
                onInvoiceClick(invoice_qb_id);
                document.body.setAttribute('id', 'body');
              }
            }}
          >
            #{invoice_number}
          </StyledCellText>
        </StyledCellContainer>
      ),
    },
    {
      key: 'invoice_amount',
      label: 'Invoice amount',
      sortable: true,
      width: '11%',
      render: ({ subtotal, currency: rowCurrency }: SalesReportRow) => (
        <StyledCellContainer $hStack $right>
          <StyledCellText>
            {subtotal ? currencyFormatter(subtotal, rowCurrency) : ''}
          </StyledCellText>
        </StyledCellContainer>
      ),
    },
    {
      key: 'status',
      label: 'Invoice status',
      sortable: false,
      width: '12%',
      render: ({ invoice_status }: SalesReportRow) => (
        <StyledCellContainer $hStack $left>
          <StyledInvoiceStatus $status={invoice_status}>
            {invoice_status}
          </StyledInvoiceStatus>
        </StyledCellContainer>
      ),
    },
    {
      key: 'close_date',
      label: 'Closed-won date',
      sortable: true,
      width: '10%',
      render: ({ close_date }: SalesReportRow) => (
        <StyledCellText>
          {close_date ? dayjs(dayjs(close_date)).format('MMM DD, YYYY') : ''}
        </StyledCellText>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <Table
        data={deals.map(setRowKey)}
        columns={columns}
        idKey="id"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        loading={loading}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        collapsible
        collapsedRow={activeRow}
        onRowClick={handleRowClick}
        rowDetailsRenderer={rowDetailsRenderer}
      />
      <StyledTablePagination
        labelDisplayedRows={() => null}
        rowsPerPageOptions={[]}
        count={count}
        rowsPerPage={pagination.limit}
        page={count ? pagination.page - 1 : 0}
        onPageChange={handleChangePage}
        ActionsComponent={(props) => (
          <PaginationActions
            {...props}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      />
    </StyledTableContainer>
  );
};

export default SalesReportTable;
