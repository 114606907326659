export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CONTRACT_TYPE = {
  CLOUDOPS: 'cloudops',
  FIXED_PRICE: 'fixed_price',
  TIME_AND_MATERIAL: 'time_and_material',
  TIME_AND_MATERIAL_AT_COST: 'time_and_material_at_cost',
  PRORATED_FIXED_PRICE: 'prorated_fixed_price',
  MIXED_PRICE: 'mixed_pricing',
  MANUALLY_CALCULATED: 'manually_calc',
  NON_BILLABLE: 'non_billable',
  NOT_SET: 'not_set',
  QUICKBOOKS: 'quickbooks',
};

export type contractType = (typeof CONTRACT_TYPE)[keyof typeof CONTRACT_TYPE];

export const contractTypeMap = {
  [CONTRACT_TYPE.TIME_AND_MATERIAL]: 'Time and Material',
  [CONTRACT_TYPE.TIME_AND_MATERIAL_AT_COST]: 'T&M at Cost',
  [CONTRACT_TYPE.PRORATED_FIXED_PRICE]: 'Prorated fixed price',
  [CONTRACT_TYPE.MIXED_PRICE]: 'Mixed pricing',
  [CONTRACT_TYPE.FIXED_PRICE]: 'Fixed price',
  [CONTRACT_TYPE.CLOUDOPS]: 'CloudOps',
  [CONTRACT_TYPE.MANUALLY_CALCULATED]: 'Manually calculated',
  [CONTRACT_TYPE.NON_BILLABLE]: 'Non-billable',
  // [CONTRACT_TYPE.NOT_SET]: 'Not set',
  // [CONTRACT_TYPE.QUICKBOOKS]: 'Quickbooks',
};

export const contractTypeFilters = [
  {
    name: 'CloudOps',
    id: 'cloudops',
  },
  {
    name: 'Fixed price',
    id: 'fixed_price',
  },
  {
    name: 'Time and material',
    id: 'time_and_material',
  },
  {
    name: 'Prorated fixed price',
    id: 'prorated_fixed_price',
  },
  {
    name: 'Mixed pricing',
    id: 'mixed_pricing',
  },
  {
    name: 'Not set',
    id: 'not_set',
  },
  {
    name: 'Quickbooks',
    id: 'quickbooks',
  },
];

export const invoiceDetailFilters = [
  {
    name: 'Invoice not yet created',
    id: 'invoice_not_created',
  },
  {
    name: 'Invoice created',
    id: 'invoice_created',
  },
  {
    name: 'Time logs not approved',
    id: 'time_logs_not_approved',
  },
  {
    name: 'Invoice creation approval required',
    id: 'invoice_creation_approval_required',
  },
  {
    name: 'Contract details wrong',
    id: 'contract_details_wrong',
  },
];
