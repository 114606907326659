import styled from 'styled-components';
import { neutrals } from 'utils/styles/color';

export const GreyTxt = styled.div`
  color: ${neutrals[500]};
  font-weight: 300;
  font-size: 0.75rem;
  line-height: normal;
`;

export default styled.div`
  color: #999999;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: normal;
  margin-top: -5px;
`;
