import React, { FC, useCallback, useState } from 'react';

import { Alert } from '@mui/material';
import Modal from 'molecules/Modal';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';

import axios from 'axios';

import { ZStreamProjectSyncDialogProps } from 'organisms/ProjectDialogs/types';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';

import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';

const CloudOpsProjectDialog: FC<ZStreamProjectSyncDialogProps> = ({
  project,
  companyHsId,
  onClose,
}) => {
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] = useState<
    string | CloudOpsProjectsType | null
  >(null);

  const handleCreateProject = useCallback(async () => {
    setFormSubmitted(true);
    if (
      cloudOpsProjectSelected &&
      typeof cloudOpsProjectSelected === 'object'
    ) {
      try {
        await axios.post(`${process.env.REACT_APP_USER_SERVICE}/projects`, {
          company_hs_id: Number(companyHsId),
          project_id:
            typeof cloudOpsProjectSelected === 'object'
              ? cloudOpsProjectSelected?.id_str
              : 0,
          type: 'CO',
        });

        setFormSubmitted(false);

        onClose(cloudOpsProjectSelected);
      } catch (err) {
        setAlert({ error: true, message: err.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
      }
    }
  }, [cloudOpsProjectSelected, companyHsId, onClose, project]);

  return (
    <Modal
      open
      maxWidth="sm"
      title="Add new project"
      subtitle="To add a new project, start typing the project name in the input field. As you type, a dropdown will appear showing matching projects retrieved from Zoho Projects. Select the appropriate project from the dropdown to proceed with adding it to the company."
      onClose={() => onClose()}
      transitionDuration={0}
    >
      <>
        <ModalContent
          $gap={16}
          sx={{ overflowY: 'hidden', pb: '8px !important' }}
        >
          {alert.message ? (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          ) : undefined}
          <ZStreamProjectAutoComplete
            disabled={false}
            submitted={formSubmitted}
            project={project}
            onChange={setCloudOpsProjectSelected}
          />
        </ModalContent>

        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={handleCreateProject}>
            Add project
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectDialog;
