import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { Box, Stack } from '@mui/material';
import Block from 'molecules/Card';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
} from 'recharts';

import {
  currencyFormatter,
  formatLargeNumber,
} from 'utils/helpers/currencyFormatter';
import PastDueChart from 'organisms/PastDueChart/PastDueChart';
import {
  StyledFilterContainer,
  StyledHorizontalBar,
} from 'organisms/InvoiceChart/InvoiceChart.styles';
import { setStatusFilter } from 'store/invoices/invoicesSlice';
import { useBaoDispatch } from 'utils/hooks/redux';
import Select from 'molecules/Select';
import SelectGrouped from 'molecules/Select/SelectGrouped';
import {
  debtorsTypeOptions,
  invoiceCurrencyOptions,
  invoiceOptions,
  invoiceTypeOptions,
} from 'organisms/InvoiceChart/data';
import { neutrals } from 'utils/styles/color';
import HeadlessTable from 'molecules/Table/HeadlessTable';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { StyledCellContainer } from 'molecules/Table/styles';
import Link from 'atoms/Link/Link';
import { MonthCompanyDetails } from 'organisms/InvoiceChart/components/MonthCompanyDetails';
import { MonthDetails } from 'organisms/InvoiceChart/components/MonthDetails';
import { DebtorCompanyDetails } from 'organisms/InvoiceChart/components/DebtorCompanyDetails';

interface InvoiceChartState {
  total_payment: number;
  total_outstanding: number;
  month: string;
  year: number;
}

export interface CompanyChartState {
  month: string;
  companies_with_invoices: number;
  total_companies: number;
  year: number;
}

export interface ICompanyModelItem {
  company: string;
  total: number;
  company_hs_id: number;
  oldest_past_due_days: number;
  highest_invoice_count: number;
  due_date: string;
}

export type DebtorCompanySortType =
  | 'highest_balance'
  | 'highest_count'
  | 'oldest_date';

interface ICompanyModel {
  items: ICompanyModelItem[];
}

// eslint-disable-next-line
const BarWithBorder = (fillColor: string) => (props: any) => {
  const {
    fill,
    x = 0,
    y = 0,
    width,
    height = 0,
    total_payment,
    total_outstanding,
  } = props;

  const totalPaymentPercent =
    (total_payment * 100) / (total_payment + total_outstanding) || 0;
  const totalPaymentHeight = (height * totalPaymentPercent) / 100 || 0;
  const heightDiff = height - totalPaymentHeight + 4 || 4;

  if (total_payment <= 0 || height - heightDiff <= 0) {
    return (
      <g>
        <rect
          x={x}
          y={y}
          rx={5}
          ry={5}
          width={width}
          height={height}
          fill={total_outstanding ? fill : fillColor}
        />
        <rect
          x={x}
          y={y + 4}
          width={width}
          height={height - 4 < 0 ? 0 : height - 4}
          fill={total_outstanding ? fill : fillColor}
        />
      </g>
    );
  }

  return (
    <g>
      <rect
        x={x}
        y={y}
        rx={5}
        ry={5}
        width={width}
        height={height}
        fill={total_outstanding ? fill : fillColor}
      />
      <rect
        x={x}
        y={y + 4}
        rx={5}
        ry={5}
        width={width}
        height={height - 4 < 0 ? 0 : height - 4}
        fill={total_outstanding ? fill : fillColor}
      />
      <rect
        x={x}
        y={y + heightDiff}
        width={width}
        height={height - heightDiff < 0 ? 0 : height - heightDiff}
        fill={fillColor || fill}
      />
    </g>
  );
};

const GlobalInvoiceChart = () => {
  const dispatch = useBaoDispatch();

  const [focusedBar, setFocusedBar] = useState<
    InvoiceChartState | CompanyChartState | ICompanyModelItem | null
  >(null);
  const [invoices, setInvoices] = useState<Array<InvoiceChartState>>([]);
  const [companies, setCompanies] = useState<Array<CompanyChartState>>([]);
  const [debtorCompanies, setDebtorCompanies] = useState<
    Array<ICompanyModelItem>
  >([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [spacing, setSpacing] = useState<number>(0);

  const [filter, setFilter] = useState({
    status: 'open',
    type: 'all',
    sort_by: 'highest_balance',
    currency: 'USD',
    region: 'usa',
  });

  const handleChangeFilter = (value: string, name?: string) => {
    if (!name) return;
    setFilter({ ...filter, [name]: value });
  };

  const handleChangeCurrency = (value: string, name?: string) => {
    if (!name) return;
    const [region, currency] = value.split('-');
    setFilter({ ...filter, region, currency });
  };

  const details = useMemo(() => {
    if (filter.status === 'open') {
      return (
        <MonthDetails
          details={focusedBar as InvoiceChartState}
          currency={filter.currency}
          totalLabel="paid"
        />
      );
    }

    if (filter.status === 'top_debtors') {
      return (
        <DebtorCompanyDetails
          details={focusedBar as ICompanyModelItem}
          currency={filter.currency}
          sortBy={filter.sort_by as DebtorCompanySortType}
        />
      );
    }

    return (
      <MonthCompanyDetails
        details={focusedBar as CompanyChartState}
        isNew={filter.status === 'new_companies'}
      />
    );
  }, [filter.status, filter.sort_by, focusedBar]);

  const tableColumns = [
    {
      id: 'company',
      key: 'company',
      sortable: false,
      label: 'Company',
      render: (item: { company_hs_id: string; company: ReactNode }) => (
        <StyledCellContainer $vStack>
          <Link to={`/company/${item.company_hs_id}`} $textDecoration="none">
            <StyledCellText $textType="link">{item.company}</StyledCellText>
          </Link>
        </StyledCellContainer>
      ),
    },
    {
      id: 'total',
      key: 'total',
      sortable: false,
      label: 'Total',
      render: (item: { total: number }) => (
        <StyledCellContainer $vStack $right>
          <StyledCellText>
            {currencyFormatter(item.total || 0, filter.currency)}
          </StyledCellText>
        </StyledCellContainer>
      ),
    },
  ];

  const tableData = useMemo(
    () =>
      debtorCompanies.filter(({ company }) => !company.startsWith('@TEST@')),
    [debtorCompanies]
  );

  useEffect(() => {
    fetchPageData();
  }, [filter]);

  async function fetchPageData() {
    try {
      if (filter.status === 'open') {
        setDebtorCompanies([]);
        setCompanies([]);
        dispatch(setStatusFilter('open'));

        const response: AxiosResponse<{ items: InvoiceChartState[] }> =
          await axios.get(
            `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/payment-and-outstanding`,
            {
              params: {
                num_of_months: 16,
                currency: filter.currency,
                region: filter.region,
                invoice_type: filter.type,
              },
            }
          );

        const items = response.data.items.map((item) => ({
          ...item,
          total: item.total_payment + item.total_outstanding ?? 0,
        }));

        setInvoices(items as Array<InvoiceChartState>);
      } else if (filter.status === 'top_debtors') {
        setTableLoading(true);
        setCompanies([]);
        setInvoices([]);
        setDebtorCompanies([]);
        const response: AxiosResponse<ICompanyModel> =
          await axios.get<ICompanyModel>(
            `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/top-ten-past-due`,
            {
              params: {
                sort_by: filter.sort_by,
                currency: filter.currency,
                region: filter.region,
              },
            }
          );
        const items = response?.data?.items || [];

        if (items.length < 10) {
          const count = 10 - items.length;
          for (let i = 0; i < count; i += 1) {
            items.push({
              company: `@TEST@_${+new Date()}`,
              total: 0,
              company_hs_id: 0,
              oldest_past_due_days: 0,
              highest_invoice_count: 0,
              due_date: '',
            });
          }
        }

        setDebtorCompanies(items); // (response?.data?.items || []);
        setTableLoading(false);
      } else {
        setDebtorCompanies([]);
        setInvoices([]);
        const response: AxiosResponse<{ items: CompanyChartState[] }> =
          await axios.get(
            `${process.env.REACT_APP_REPORT_SERVICE}/company/count`,
            {
              params: {
                currency: filter.currency,
                region: filter.region,
                new_company: filter.status === 'new_companies',
              },
            }
          );

        setCompanies(response.data.items as Array<CompanyChartState>);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function getInputValue(selected: string) {
    if (!selected) return { currency: 'USD', country: 'US QuickBooks' };

    const [country, currency] = selected.split('-');
    if (country === 'usa') {
      return { currency, country: 'US QuickBooks' };
    }

    // TODO: Add Canadian QuickBooks
    // if (country === 'cad') {
    //   return { currency, country: 'Canadian QuickBooks' };
    // }

    return { currency, country: 'European QuickBooks' };
  }

  const dataKey = (() => {
    if (filter.sort_by === 'highest_count') return 'highest_invoice_count';
    if (filter.sort_by === 'oldest_date') return 'oldest_past_due_days';
    return 'total';
  })();

  return (
    <Block
      title="Statistics"
      avatar={<CardAvatar />}
      action={details}
      sx={{
        position: 'relative',
        height: '100%',
        '.MuiCardContent-root': {
          padding: '0 12px 24px 12px !important',
        },
      }}
    >
      <Stack gap="12px">
        <StyledFilterContainer>
          <Select
            name="status"
            defaultValue="open"
            options={invoiceOptions}
            onSelect={handleChangeFilter}
          />
          {filter.status === 'open' && (
            <Select
              name="type"
              defaultValue="all"
              options={invoiceTypeOptions}
              onSelect={handleChangeFilter}
            />
          )}
          {filter.status === 'top_debtors' && (
            <Select
              name="sort_by"
              defaultValue="highest_balance"
              options={debtorsTypeOptions}
              onSelect={handleChangeFilter}
            />
          )}
          <SelectGrouped
            name="currency"
            defaultValue="usa-USD"
            onSelect={handleChangeCurrency}
            options={invoiceCurrencyOptions}
            renderValue={(selectValue: string) => {
              // eslint-disable-next-line
              // @ts-ignore
              const { currency, country } = getInputValue(selectValue);
              return (
                <>
                  <span>{currency}</span>
                  {country && <span> | {country}</span>}
                </>
              );
            }}
          />
        </StyledFilterContainer>

        {/* eslint-disable-next-line */}
        {filter.status === 'open' ? (
          <Box height={190}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={invoices}
                barGap={16}
                onMouseMove={(state: {
                  activeTooltipIndex?: number;
                  isTooltipActive?: boolean;
                }) => {
                  const { isTooltipActive, activeTooltipIndex } = state;
                  if (isTooltipActive) {
                    setFocusedBar(
                      invoices[activeTooltipIndex as number] || null
                    );
                  } else {
                    setFocusedBar(null);
                  }
                }}
                onMouseLeave={() => setFocusedBar(null)}
              >
                <CartesianGrid vertical={false} />
                <Bar
                  stackId="a"
                  dataKey="total"
                  fill="#FEDEDE"
                  barSize={48}
                  radius={[5, 5, 0, 0]}
                  shape={BarWithBorder('#F5BABA')}
                  activeBar={BarWithBorder('#F5BABA')}
                />
                <XAxis
                  dataKey="month"
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0,
                    color: neutrals[700],
                  }}
                  tickFormatter={(tick, index) => {
                    const { year } = invoices[index] || {};
                    const yearStr = `${
                      year === new Date().getFullYear()
                        ? ''
                        : `, ${String(year).substring(2, 4)}`
                    }`;
                    return `${tick.substring(0, 3)}${yearStr}`;
                  }}
                />
                <YAxis
                  width={spacing}
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0,
                    color: neutrals[700],
                  }}
                  tickCount={5}
                  tickFormatter={(value) => {
                    const displayValue = formatLargeNumber(
                      Number(value),
                      filter.currency
                    );
                    const yAxisSpace = 27 + displayValue.length * 3;
                    setSpacing((prev) =>
                      prev > yAxisSpace ? prev : yAxisSpace
                    );

                    return displayValue;
                  }}
                />
                <Tooltip
                  cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                  content={() => ''}
                  labelFormatter={() => ''}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : filter.status === 'top_debtors' ? (
          <Stack direction="row" spacing={2} height={274}>
            <Box sx={{ width: '30%', mt: '1px' }}>
              <HeadlessTable
                loading={tableLoading}
                emptyText=" "
                idKey="company_hs_id"
                hover={false}
                columns={tableColumns}
                data={tableData}
                sx={{
                  '& .MuiTableCell-root': {
                    padding: '4px 8px !important',
                    height: 'auto !important',
                    borderBottom: 'none !important',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: 800,
                height: 272,
                '.recharts-layer.recharts-cartesian-axis-tick:not(:last-of-type)':
                  {
                    transform: 'translateX(-10px)',
                  },
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={debtorCompanies}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  barGap={0}
                  onMouseMove={(state: {
                    activeTooltipIndex?: number;
                    isTooltipActive?: boolean;
                  }) => {
                    const { isTooltipActive, activeTooltipIndex } = state;
                    if (isTooltipActive) {
                      setFocusedBar(
                        debtorCompanies[activeTooltipIndex as number] || null
                      );
                    } else {
                      setFocusedBar(null);
                    }
                  }}
                  onMouseLeave={() => setFocusedBar(null)}
                >
                  <CartesianGrid horizontal={false} />
                  <Bar
                    dataKey={dataKey}
                    fill="#F5BABA"
                    barSize={16}
                    radius={[0, 5, 5, 0]}
                  />
                  <XAxis
                    type="number"
                    height={20}
                    tickLine={false}
                    tick={{
                      fontSize: 12,
                      stroke: neutrals[700],
                      strokeWidth: 0,
                      color: neutrals[700],
                    }}
                    tickFormatter={(tick) => {
                      if (tick === 0 || filter.sort_by === 'highest_balance')
                        return '';
                      return tick;
                    }}
                    tickMargin={0}
                  />
                  <YAxis
                    dataKey="company"
                    type="category"
                    tickLine={false}
                    tickFormatter={() => ''}
                    width={40}
                  />
                  <Tooltip
                    cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                    content={() => ''}
                    labelFormatter={() => ''}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Stack>
        ) : (
          <Box height={190}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={companies}
                barGap={8}
                onMouseMove={(state: {
                  activeTooltipIndex?: number;
                  isTooltipActive?: boolean;
                }) => {
                  const { isTooltipActive, activeTooltipIndex } = state;
                  if (isTooltipActive) {
                    setFocusedBar(
                      companies[activeTooltipIndex as number] || null
                    );
                  } else {
                    setFocusedBar(null);
                  }
                }}
                onMouseLeave={() => setFocusedBar(null)}
              >
                <CartesianGrid vertical={false} />
                <Bar
                  dataKey="total_companies"
                  fill="#F5BABA"
                  barSize={20}
                  radius={[5, 5, 0, 0]}
                  activeBar={{ fill: '#F5BABA', strokeWidth: 2 }}
                />
                <Bar
                  dataKey="companies_with_invoices"
                  fill="#FEDEDE"
                  barSize={20}
                  radius={[5, 5, 0, 0]}
                  activeBar={{ fill: '#FEDEDE', strokeWidth: 2 }}
                />
                <XAxis
                  dataKey="month"
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0,
                    color: neutrals[700],
                  }}
                  tickFormatter={(tick, index) => {
                    const { year } = companies[index] || {};
                    const yearStr = `${
                      year === new Date().getFullYear()
                        ? ''
                        : `, ${String(year).substring(2, 4)}`
                    }`;
                    return `${tick.substring(0, 3)}${yearStr}`;
                  }}
                />
                <YAxis
                  width={30}
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0.5,
                    color: neutrals[700],
                  }}
                />
                <Tooltip
                  cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                  content={() => ''}
                  labelFormatter={() => ''}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}

        {filter.status !== 'top_debtors' && (
          <StyledHorizontalBar>
            {filter.status === 'open' && (
              <PastDueChart currency={filter.currency} region={filter.region} />
            )}
          </StyledHorizontalBar>
        )}
      </Stack>
    </Block>
  );
};

export default GlobalInvoiceChart;
