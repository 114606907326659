import styled from 'styled-components';
import { Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';
import React from 'react';

const StyledHeadText = styled(({ ...props }) => <Typography {...props} />)`
  && {
    overflow: hidden;
    color: ${neutrals[600]};
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }
`;

const StyledGreyText = styled(({ ...props }) => <Typography {...props} />)`
  && {
    overflow: hidden;
    color: ${neutrals[600]};
    text-overflow: ellipsis;

    font-size: 0.69rem;
    font-weight: 300;
    line-height: 14px;
  }
`;

const StyledCellText = styled(Typography)<{
  $textType?: 'bold' | 'link' | 'grey' | 'default';
  $color?: string;
  $size?: 'small' | 'medium';
}>`
  && {
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    // font-size: ${({ $size }) =>
      $size === 'small' ? '0.75rem' : '0.813rem'};
    line-height: ${({ $size }) => ($size === 'small' ? '16px' : '18px')};
    font-style: normal;

    ${({ $textType }) => $textType === 'link' && `display: inline-block;`}
    color: ${({ $color }) => $color || neutrals[700]};

    font-size: ${({ $textType, $size }) => {
      if ($textType === 'bold') return '0.875rem';
      switch ($size) {
        case 'small':
          return '0.75rem';
        default:
          return '0.813rem';
      }
    }};

    font-weight: ${({ $textType }) => {
      switch ($textType) {
        case 'bold':
          return 500;
        case 'link':
          return 400;
        default:
          return 400;
      }
    }};

    ${({ $textType }) =>
      $textType === 'grey' && {
        fontSize: '0.69rem',
        fontWeight: 300,
        lineHeight: '14px',
      }}

    ${({ $textType }) =>
      $textType === 'link' && {
        color: palette.primary.main,
        cursor: 'pointer',
      }}

    &:hover {
      ${({ $textType }) =>
        $textType === 'link' &&
        `
          color: ${palette.primary.main};
          text-decoration: underline;
          cursor: pointer;
        `}
    }
  }
`;

const StyledCellText2 = styled(Typography)<{
  $color?: string;
}>`
  && {
    display: flex;
    align-items: center;
    height: 24px;

    overflow: hidden;
    color: ${({ $color }) => $color || neutrals[700]};
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const StyledDetailsText = styled(Typography)<{
  $bold?: boolean;
  $divider?: boolean;
  $dividerTop?: boolean;
  $textTransform?: string;
}>`
  && {
    position: relative;
    overflow: hidden;
    color: ${neutrals[700]};
    text-overflow: ellipsis;
    text-transform: ${({ $bold, $textTransform }) =>
      $textTransform || ($bold ? 'capitalize' : 'none')};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
    line-height: 16px;

    &::before {
      content: '';
      width: 100%;
      height: 4px;
      display: ${({ $dividerTop }) => ($dividerTop ? 'block' : 'none')};
      border-top: ${({ $dividerTop }) =>
        $dividerTop ? '1px solid #e8e9eb' : 'none'};
    }

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      display: ${({ $divider }) => ($divider ? 'block' : 'none')};
      border-bottom: ${({ $divider }) =>
        $divider ? '1px solid #e8e9eb' : 'none'};
    }
  }
`;

export {
  StyledHeadText,
  StyledGreyText,
  StyledCellText,
  StyledCellText2,
  StyledDetailsText,
};
