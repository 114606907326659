import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Stack } from '@mui/material';
import {
  Form,
  StyledAuthTextField,
  StyledErrorMessage,
} from 'pages/AuthPage/AuthPage.styles';
import { validEmail } from 'utils/helpers/formValidators';
import { palette } from 'utils/styles/variables';
import Link from 'atoms/Link/Link';
import { AlertContainer2 } from 'utils/styles/AlertWrapper';
import axios from 'axios';

const ForgotForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState({
    value: '',
    error: '',
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState({
    value: '',
    error: false,
  });

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    let { error } = email;
    if (validEmail(newEmail)) {
      error = '';
    }
    setEmail((prevState) => ({
      ...prevState,
      value: newEmail,
      error,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validEmail(email.value)) {
      return setEmail((prevState) => ({
        ...prevState,
        error: 'Please enter valid email address!',
      }));
    }
    if (success) {
      navigate('/signin');
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_USER_SERVICE}/forgot-password`,
          {
            email: email.value,
          }
        );
        setSuccess(true);
        setApiResponse({ value: response.data.message, error: false });
      } catch (err) {
        setSuccess(false);
        setApiResponse({
          value: err.response.data.message,
          error: true,
        });
      }
    }

    // todo remove this return statement and add API call
    return false;
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {apiResponse.value && !apiResponse.error ? (
        <AlertContainer2
          sx={{
            pt: '32px',
            pb: '16px',
            '.MuiPaper-root': {
              p: '8px !important',
              borderRadius: '4px !important',
              justifyContent: 'start !important',
            },
            '.MuiAlert-message': { textTransform: 'unset !important' },
          }}
        >
          <Alert severity="success">
            We have sent the password recovery link to:
            <p style={{ margin: 0, textDecoration: 'underline' }}>
              {email?.value || ''}
            </p>
          </Alert>
        </AlertContainer2>
      ) : (
        <Stack gap="14px">
          <Stack gap={0} height="74px">
            <StyledAuthTextField
              fullWidth
              type="email"
              label="Email"
              margin="normal"
              variant="outlined"
              autoComplete="email"
              disabled={success}
              error={!!email.error}
              helperText={email.error}
              onChange={handleEmailChange}
              sx={{ mt: 2, mb: 0 }}
              InputLabelProps={{ shrink: true }}
            />
            <StyledErrorMessage>
              {state?.errorType ? state.errorType.replaceAll('_', ' ') : ''}
            </StyledErrorMessage>
            <StyledErrorMessage>
              {apiResponse.value ? apiResponse.value : ''}
            </StyledErrorMessage>
          </Stack>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{
              textTransform: 'uppercase',
              lineHeight: '20px',
              borderRadius: '3px',
            }}
          >
            Continue
          </Button>
        </Stack>
      )}
      <Stack alignItems="end" mt="16px">
        <Link
          to="/signin"
          $fontWeight={500}
          $fontSize="0.75rem"
          $lineHeight="18px"
          $color={palette.primary.main}
        >
          Back to log in page
        </Link>
      </Stack>
    </Form>
  );
};

export default ForgotForm;
