import React, { ChangeEvent, FormEvent, useState } from 'react';
import { TextField, Typography, Alert } from '@mui/material';
import { validEmail } from 'utils/helpers/formValidators';
import { Form } from 'pages/AuthPage/AuthPage.styles';
import { palette } from 'utils/styles/variables';
import Link from 'atoms/Link/Link';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { AlertContainer } from 'utils/styles/AlertWrapper';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

type FormProps = {
  request: { value: string; error: string };
  email: { value: string; error: string };
};

const ContactUsForm = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormProps>({
    request: { value: '', error: '' },
    email: { value: '', error: '' },
  });
  const [apiResponse, setApiResponse] = useState({
    value: '',
    submitted: false,
    error: false,
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const updatedForm = cloneDeep(form);
    let error = false;
    if (!validEmail(form.email.value)) {
      updatedForm.email.error = 'Please enter valid email address!';
      error = true;
    }
    setForm(updatedForm);
    if (error) {
      return false;
    }
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/contact`,
        {
          params: {
            email: form.email.value,
            body: form.request.value,
          },
        }
      );

      return setApiResponse({
        value: response.data.message,
        error: false,
        submitted: true,
      });
    } catch (err) {
      return setApiResponse({
        value: err.response.data.message,
        submitted: false,
        error: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof FormProps
  ) => {
    const updatedForm = cloneDeep(form);
    const newValue = e.target.value;
    if (field === 'email') {
      if (validEmail(newValue)) {
        updatedForm.email.error = '';
      }
    } else if (field === 'request') {
      if (newValue) {
        updatedForm.request.error = '';
      }
    }
    updatedForm[field].value = newValue;
    setForm(updatedForm);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {state?.errorType && (
        <AlertContainer>
          <Alert severity="error">{state.errorType.replaceAll('_', ' ')}</Alert>
        </AlertContainer>
      )}
      {apiResponse.value && (
        <AlertContainer>
          <Alert severity={apiResponse.error ? 'error' : 'success'}>
            {apiResponse.value}
          </Alert>
        </AlertContainer>
      )}
      <TextField
        disabled={apiResponse.submitted}
        error={!!form.email.error}
        autoComplete="email"
        fullWidth
        size="small"
        variant="outlined"
        label="Enter your email"
        placeholder=""
        margin="normal"
        type="email"
        onChange={(e) => handleChange(e, 'email')}
        helperText={form.email.error}
        value={form.email.value}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '.MuiInputBase-input': { fontSize: '0.875rem' },
        }}
      />
      <TextField
        disabled={apiResponse.submitted}
        error={!!form.request.error}
        autoComplete="request"
        size="small"
        fullWidth
        variant="outlined"
        label="Describe your request"
        margin="normal"
        onChange={(e) => handleChange(e, 'request')}
        helperText={form.request.error}
        value={form.request.value}
        multiline
        rows={6}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '.MuiInputBase-input': { fontSize: '0.875rem' },
        }}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        disabled={apiResponse.submitted}
        loading={loading}
        sx={{ margin: '24px 0' }}
      >
        SEND
      </LoadingButton>

      <Typography
        textAlign="right"
        variant="body1"
        fontSize="0.875rem"
        color="#999999"
        className="form-footer"
      >
        <Link to="/signin" $color={palette.primary.main}>
          Back to Log in page.
        </Link>
      </Typography>
    </Form>
  );
};

export default ContactUsForm;
