import React, { useState } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { StyledAuthTextField } from 'pages/AuthPage/AuthPage.styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordTextField = (textFieldProps: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledAuthTextField
      {...textFieldProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        inputProps: {
          // maxLength: 64,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
              sx={{
                svg: {
                  fontSize: '18px',
                },
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
