import React, { FC, useState } from 'react';

import Modal from 'molecules/Modal';
import CloudOpsProjectInfoDialog from 'organisms/ProjectDialogs/CloudOpsProjectInfoDialog';
import CloudOpsProjectInvoiceCreationDialog from 'organisms/ProjectDialogs/CloudOpsProjectInvoiceCreationDialog';
import CloudOpsProjectInvoiceCreationApprovalDialog from 'organisms/ProjectDialogs/CloudOpsProjectInvoiceCreationApprovalDialog';
import QuickBookProjectSyncDialog from 'organisms/ProjectDialogs/QuickBookProjectSyncDialog';
import CloudOpsProjectDeleteDialog from 'organisms/ProjectDialogs/CloudOpsProjectDeleteDialog';
import { ModalContent } from 'molecules/Modal/Modal.styles';
import { IconButton, Stack } from '@mui/material';
import {
  StyledRow,
  StyledRowText,
  StyledTextButton,
  DialogSectionTitle,
} from 'organisms/ProjectDialogs/CloudOpsProject.styles';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { neutrals } from 'utils/styles/color';
import axios from 'axios';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import dayjs from 'dayjs';
import { CONTRACT_TYPE, contractTypeMap } from 'utils/constants/common';

import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';
import { palette } from 'utils/styles/variables';
import CloudOpsProjectInvoiceCreationInfoDialog from 'organisms/ProjectDialogs/CloudOpsProjectInvoiceCreationInfoDialog';
import { formatHours } from 'utils/helpers/dates';

export const invoiceCreationContractTypes = [
  CONTRACT_TYPE.TIME_AND_MATERIAL,
  CONTRACT_TYPE.FIXED_PRICE,
  CONTRACT_TYPE.MIXED_PRICE,
  CONTRACT_TYPE.CLOUDOPS,
  CONTRACT_TYPE.PRORATED_FIXED_PRICE,
];
const hideApprovalButtonTypes = [
  CONTRACT_TYPE.NOT_SET,
  CONTRACT_TYPE.MANUALLY_CALCULATED,
  CONTRACT_TYPE.NON_BILLABLE,
];

const CloudOpsProjectDetailsDialog: FC<{
  currency?: keyof typeof currencyIcon;
  onClose: () => void;
  onUpdate: () => void;
  project: CloudOpsProjectsType;
  companyHsId?: string;
}> = ({ currency, onClose, onUpdate, project, companyHsId }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [open, setOpen] = useState<boolean>(true);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showInvoiceCreationInfo, setShowInvoiceCreationInfo] =
    useState<boolean>(false);
  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showInvoiceCreation, setShowInvoiceCreation] =
    useState<boolean>(false);
  const [showInvoiceCreationApproval, setShowInvoiceCreationApproval] =
    useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const {
    name,
    key,
    // quickbook_project_id,
    monthly_budget_in_hours,
    over_budget_hourly_rate,
    quickbook_project_name,
    // status,
    report_count,
    // TODO: 18.01 - check if it's correct?
    completion_date,
    contract_type,
    automatic_invoice_creation,
    invoice_creation_approval_required,
    monthly_fee,
  } = project;

  const onInfoClick = () => {
    setShowInfo(true);
    setOpen(false);
  };

  const onInfoInvoiceCreationClick = () => {
    setShowInvoiceCreationInfo(true);
    setOpen(false);
  };

  const onInfoClose = () => {
    setShowInvoiceCreationInfo(false);
    setShowInfo(false);
    setOpen(true);
  };

  const onShowInvoiceCreation = () => {
    setShowInvoiceCreation(true);
    setOpen(false);
  };

  const onShowInvoiceCreationApproval = () => {
    setShowInvoiceCreationApproval(true);
    setOpen(false);
  };

  const onDeleteBtnClick = async () => {
    setShowDeleteDialog(true);
    setOpen(false);
  };

  const onDeleteProject = async () => {
    try {
      const id = project.id_str || String(project.id || 0);
      await axios.delete(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${id}`
      );

      onUpdate();
      onClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setShowDeleteDialog(false);
    }
  };

  const onSyncBtnClick = () => {
    setShowSyncDialog(true);
    setOpen(false);
  };

  const onCloseDialog = () => {
    setShowInvoiceCreation(false);
    setShowInvoiceCreationApproval(false);
    setShowSyncDialog(false);
    setShowDeleteDialog(false);

    onUpdate();
    setOpen(true);
  };

  // const termColor = status === 'active' ? neutrals[800] : neutrals[400];
  const isAbleManageApproval = (() =>
    contract_type &&
    !hideApprovalButtonTypes.includes(contract_type) &&
    isSuperAdmin)();
  const isAbleDelete = (() => {
    if (!isSuperAdmin) return false;
    return !report_count || report_count <= 1;
  })();

  return (
    <>
      <Modal
        open={open}
        padding24
        maxWidth="sm"
        title="Project settings"
        onClose={onClose}
        transitionDuration={0}
      >
        <>
          <ModalContent sx={{ padding: '0 16px 24px !important' }}>
            <Stack gap="8px">
              <StyledRow>
                <StyledRowText>
                  Project name: <StyledRowText $bold>{name}</StyledRowText>
                </StyledRowText>
                <StyledRowText $color={neutrals[400]}>{key}</StyledRowText>
              </StyledRow>

              <StyledRow>
                <StyledRowText>
                  QB project:{' '}
                  <StyledRowText
                    $bold={!!quickbook_project_name}
                    $color={
                      quickbook_project_name
                        ? neutrals[800]
                        : palette.warning[600]
                    }
                  >
                    {quickbook_project_name || 'Project is not synced'}
                  </StyledRowText>
                </StyledRowText>
                <StyledTextButton variant="text" onClick={onSyncBtnClick}>
                  {quickbook_project_name ? 'Edit' : 'Sync'}
                </StyledTextButton>
              </StyledRow>

              {isAbleDelete && (
                <StyledRow>
                  <StyledRowText>Project has no invoices</StyledRowText>
                  <StyledTextButton variant="text" onClick={onDeleteBtnClick}>
                    Delete the project
                  </StyledTextButton>
                </StyledRow>
              )}

              <StyledRow>
                <DialogSectionTitle $custom sx={{ fontSize: '0.875rem' }}>
                  Billing automation details
                </DialogSectionTitle>
              </StyledRow>

              <StyledRow>
                <StyledRowText>
                  <span style={{ display: 'inline-flex' }}>
                    Invoicing automation{' '}
                    <IconButton
                      sx={{ padding: '0' }}
                      aria-label="info"
                      onClick={onInfoClick}
                    >
                      <InfoIcon height={18} color={neutrals[200]} />
                    </IconButton>
                    :
                  </span>
                  <StyledRowText
                    $bold
                    $color={
                      automatic_invoice_creation
                        ? palette.success[500]
                        : palette.warning[600]
                    }
                  >
                    {automatic_invoice_creation ? ' ON' : ' OFF'}
                  </StyledRowText>
                </StyledRowText>
                {isAbleManageApproval && (
                  <StyledTextButton
                    variant="text"
                    onClick={onShowInvoiceCreation}
                  >
                    {automatic_invoice_creation ? ' Switch OFF' : ' Switch ON'}
                  </StyledTextButton>
                )}
              </StyledRow>

              {invoiceCreationContractTypes.includes(contract_type) && (
                <StyledRow>
                  <StyledRowText>
                    <span style={{ display: 'inline-flex' }}>
                      Invoice creation approval required{' '}
                      <IconButton
                        sx={{ padding: '0' }}
                        aria-label="info"
                        onClick={onInfoInvoiceCreationClick}
                      >
                        <InfoIcon height={18} color={neutrals[200]} />
                      </IconButton>
                      :
                    </span>
                    <StyledRowText
                      $bold
                      $color={
                        invoice_creation_approval_required
                          ? palette.success[500]
                          : palette.warning[600]
                      }
                    >
                      {invoice_creation_approval_required ? ' ON' : ' OFF'}
                    </StyledRowText>
                  </StyledRowText>

                  {isSuperAdmin && (
                    <StyledTextButton
                      variant="text"
                      onClick={onShowInvoiceCreationApproval}
                    >
                      {invoice_creation_approval_required
                        ? ' Switch OFF'
                        : ' Switch ON'}
                    </StyledTextButton>
                  )}
                </StyledRow>
              )}

              {invoiceCreationContractTypes.includes(contract_type) &&
                completion_date && (
                  <StyledRow>
                    <StyledRowText>
                      Completion month:{' '}
                      <StyledRowText $bold>
                        {dayjs(new Date(completion_date)).format('MMM, YYYY')}
                      </StyledRowText>
                    </StyledRowText>
                  </StyledRow>
                )}

              <StyledRow>
                <StyledRowText>
                  Contract type:{' '}
                  <StyledRowText
                    $bold={Boolean(contract_type)}
                    $color={contract_type ? undefined : palette.warning[600]}
                  >
                    {contract_type
                      ? contractTypeMap[contract_type] || contract_type
                      : 'Not set'}
                  </StyledRowText>
                </StyledRowText>
              </StyledRow>

              {[CONTRACT_TYPE.FIXED_PRICE, CONTRACT_TYPE.MIXED_PRICE].includes(
                contract_type
              ) && (
                <StyledRow>
                  <StyledRowText>Monthly Fee</StyledRowText>
                  <StyledRowText $bold>
                    {currencyFormatter(Number(monthly_fee ?? 0), currency)}
                  </StyledRowText>
                </StyledRow>
              )}

              {contract_type === CONTRACT_TYPE.CLOUDOPS && (
                <>
                  <StyledRow>
                    <StyledRowText>Monthly hours budget</StyledRowText>
                    <StyledRowText>
                      {formatHours(monthly_budget_in_hours || 0)}
                      {' h'}
                    </StyledRowText>
                  </StyledRow>

                  <StyledRow>
                    <StyledRowText>Base monthly fee</StyledRowText>
                    {monthly_fee &&
                    typeof monthly_fee === 'number' &&
                    monthly_fee >= 0 ? (
                      <StyledRowText>
                        {currencyFormatter(Number(monthly_fee), currency)}
                      </StyledRowText>
                    ) : (
                      <StyledRowText $color={palette.error[450]}>
                        Invalid format number in Zoho Projects
                      </StyledRowText>
                    )}
                  </StyledRow>

                  <StyledRow>
                    <StyledRowText>Over budget hourly rate</StyledRowText>
                    <StyledRowText>
                      {currencyFormatter(
                        Number(over_budget_hourly_rate),
                        currency
                      )}
                    </StyledRowText>
                  </StyledRow>
                </>
              )}
            </Stack>
          </ModalContent>
        </>
      </Modal>

      {showInfo && <CloudOpsProjectInfoDialog onClose={onInfoClose} />}

      {showInvoiceCreationInfo && (
        <CloudOpsProjectInvoiceCreationInfoDialog onClose={onInfoClose} />
      )}

      {showInvoiceCreation && (
        <CloudOpsProjectInvoiceCreationDialog
          project={project}
          onClose={() => {
            setShowInvoiceCreation(false);
            setOpen(true);
          }}
          onAfterClose={onCloseDialog}
        />
      )}

      {showInvoiceCreationApproval && (
        <CloudOpsProjectInvoiceCreationApprovalDialog
          project={project}
          onClose={() => {
            setShowInvoiceCreationApproval(false);
            setOpen(true);
          }}
          onAfterClose={onCloseDialog}
        />
      )}

      {/* Sync project dialog */}
      {showSyncDialog && (
        <QuickBookProjectSyncDialog
          project={project}
          companyHsId={companyHsId}
          onClose={() => {
            setShowSyncDialog(false);
            setOpen(true);
          }}
          onAfterClose={onCloseDialog}
          onSync={onUpdate}
        />
      )}

      {showDeleteDialog && (
        <CloudOpsProjectDeleteDialog
          onClose={() => {
            setShowDeleteDialog(false);
            setOpen(true);
          }}
          onDelete={onDeleteProject}
        />
      )}
    </>
  );
};

export default CloudOpsProjectDetailsDialog;
