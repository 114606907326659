import Modal from 'molecules/Modal';
import React, { FC, useState } from 'react';
import {
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';

const CloudOpsProjectDeleteDialog: FC<{
  onClose: () => void;
  onDelete: () => void;
}> = ({ onClose, onDelete }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  const onShowCaptcha = () => {
    setOpen(false);
    setShowCaptcha(true);
  };

  return (
    <>
      <Modal
        open={open}
        maxWidth="sm"
        title="Delete the project"
        subtitle="After project is deleted from Zazmic-Connect it can be added again and assigned to any company in Zazmic-Connect."
        transitionDuration={0}
        onClose={onClose}
      >
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onShowCaptcha}>
            Delete
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal onComplete={onDelete} onClose={onClose} />
      ) : (
        <span />
      )}
    </>
  );
};

export default CloudOpsProjectDeleteDialog;
