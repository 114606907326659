import styled from 'styled-components';
import { Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const MobilePaymentInProgress = styled(Box)`
  && {
    display: flex;
    padding: 8px 16px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .MuiTypography-root {
      color: ${neutrals[700]};
      text-align: justify;

      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
`;

export const PageWrapper = styled.div`
  .text-gray {
    color: #676c73;
  }

  * {
    // font-family: Poppins !important;
  }
`;

export const TableWrapper = styled.div`
  .MuiTableRow-head {
    height: 30px !important;

    .MuiTableCell-head {
      line-height: 18px;
      font-size: 17px;
      font-weight: 700 !important;
      color: #45484d;
      font-family: Poppins;
    }

    .MuiTableCell-head:nth-child(3) {
      width: 20%;
      text-align: right;
    }
  }

  .MuiTableRow-root {
    height: 30px !imssportant;

    .MuiTableCell-body {
      line-height: 18px;
      font-size: 14px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-line;
      line-height: 24px;
      color: #505459;
    }

    .MuiTableCell-body:nth-child(1) {
      width: 30%;
      text-align: left;
      font-weight: 500;
    }

    .MuiTableCell-body:nth-child(2) {
      width: 50%;
      text-align: left;
      font-weight: 400;
    }

    .MuiTableCell-body:nth-child(3) {
      width: 20%;
      text-align: right;
      font-weight: 500;
    }
  }
`;

export const BillContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BillContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-top: 12px;
`;

export const BillContentRowLabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: start;
  min-width: 52px;
  max-width: 52px;
  font-size: 17px;
  font-weight: 600;
  margin-right: 12px;
  padding-left: 40px;
  line-height: 32px;
  color: #3e444d;
  font-family: Poppins;
`;

export const BillContentRowValueColumnTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  width: 250px;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  font-family: Poppins;
  color: #3e444d;
`;

export const BillContentRowValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  width: 250px;
  font-size: 16px;
  font-weight: 500;
  flex-grow: 1;
  line-height: 32px;
  color: #505459;
  font-family: Poppins;
`;

export const BillContentRowValueRow = styled.div`
  font-size: 14px;
  line-height: 32px;
  font-family: Poppins;
  color: #3e444d;
  font-weight: 400;
`;

export const InvoiceInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 490px;
  // margin-left: 56px;
  padding-left: 64px;
`;

export const InvoiceInformationLabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  font-size: 15px;
  font-weight: 500;
  min-width: 200px;
  line-height: 32px;
  color: #3e444d;
  font-family: Poppins;
`;

export const InvoiceInformationValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  font-size: 16px;
  font-weight: 400;
  min-width: 160px;
  flex-grow: 1;
  line-height: 32px;
  margin-right: 112px;
  color: #3e444d;
  font-family: Poppins;
`;

export const ButtonWrapper = styled.div`
  .MuiButton-root {
    border: 1px solid #6f9fe9;
    background-color: #6f9fe9;
    width: 186px;
    height: 54px;
    font-size: 14px;
    font-weight: 500px;
    border-radius: 3px;
    font-family: Poppins;

    &:hover {
      background-color: #8aafe9;
      border-color: #8aafe9;
    }

    &:disabled {
      background: #e8e9eb;
      border-color: #e8e9eb;
    }
  }
`;
