import React, { FC, useState, useCallback } from 'react';

import Table from 'molecules/Table';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import Link from 'atoms/Link/Link';

import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';

import { snakeCase } from 'lodash-es';
import { palette } from 'utils/styles/variables';
import { contractTypeMap } from 'utils/constants/common';

import { ReactComponent as SettingsIcon } from 'assets/icons/edit-settings.svg';

import { Order } from 'organisms/InvoicesTable/types';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';

export const initialValue = { page: 1, limit: 20 };

const ProjectsTable: FC<{
  loading?: boolean;
  data: { projects: CloudOpsProjectsType[]; count: number };
  onChange: (f: typeof initialValue, s?: string) => void;
  onSettings: (s: CloudOpsProjectsType) => void;
}> = ({
  loading,
  data: { projects = [], count = 0 },
  onChange,
  onSettings,
}) => {
  const [pagination, setPagination] = useState(initialValue);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');

  const handleRequestSort = useCallback(
    async (event: React.MouseEvent<unknown>, property: 'name') => {
      const isAsc = orderBy === property && order === 'asc';
      const nextState = { ...pagination, page: 1 };

      onChange(
        nextState,
        `sort[${snakeCase(property)}]=${isAsc ? 'desc' : 'asc'}`
      );

      setPagination(nextState);
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangeRowsPerPage = useCallback(
    async (option: number) => {
      const nextState = { limit: option, page: 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [orderBy, order, pagination, onChange]
  );

  const columns = [
    {
      key: 'name',
      label: 'Project name',
      sortable: true,
      width: '27%',
      render: ({ name }: CloudOpsProjectsType) => (
        <StyledCellText>{name}</StyledCellText>
      ),
    },
    {
      key: 'company_name',
      label: 'Company name',
      sortable: true,
      width: '27%',
      render: ({ company_name, company_hs_id }: CloudOpsProjectsType) => (
        <Link to={`/company/${company_hs_id}`} $textDecoration="none">
          <StyledCellText $textType="link">{company_name}</StyledCellText>
        </Link>
      ),
    },
    {
      key: 'contract_type',
      label: 'Contract type',
      sortable: true,
      width: '14%',
      render: ({
        contract_type,
        id_str,
        quickbook_project_id,
      }: CloudOpsProjectsType) => {
        if (!id_str && quickbook_project_id)
          return (
            <StyledCellText $color={palette.warning[600]}>
              Quickbooks project
            </StyledCellText>
          );

        if (!contract_type) {
          return (
            <StyledCellText $color={palette.warning[600]}>
              Not set
            </StyledCellText>
          );
        }

        return (
          <StyledCellText>{contractTypeMap[contract_type]}</StyledCellText>
        );
      },
    },
    {
      key: 'automatic_invoice_creation',
      label: 'Invoicing automation',
      sortable: true,
      width: '10%',
      render: ({
        automatic_invoice_creation,
        id_str,
        quickbook_project_id,
      }: CloudOpsProjectsType) => {
        if (!id_str && quickbook_project_id) return '';
        if (typeof automatic_invoice_creation !== 'boolean') return '';

        return (
          <StyledCellText
            $color={
              automatic_invoice_creation
                ? palette.success[500]
                : palette.warning[600]
            }
          >
            {automatic_invoice_creation ? 'ON' : 'OFF'}
          </StyledCellText>
        );
      },
    },
    {
      key: 'invoice_creation_approval_required',
      label: 'Accountant approval',
      sortable: true,
      width: '10%',
      render: ({
        invoice_creation_approval_required,
        id_str,
        quickbook_project_id,
      }: CloudOpsProjectsType) => {
        if (!id_str && quickbook_project_id) return '';
        if (typeof invoice_creation_approval_required !== 'boolean') return '';

        return (
          <StyledCellText
            $color={
              invoice_creation_approval_required
                ? palette.success[500]
                : palette.warning[600]
            }
          >
            {invoice_creation_approval_required ? 'ON' : 'OFF'}
          </StyledCellText>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '10%',
      render: (project: CloudOpsProjectsType) => (
        <StyledCellContainer $right $hStack>
          <SettingsIcon
            onClick={() => onSettings(project)}
            style={{
              color: '#6F9FE9',
              position: 'relative',
              cursor: 'pointer',
            }}
          />
        </StyledCellContainer>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <Table
        data={projects}
        columns={columns}
        idKey="id"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        loading={loading}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <StyledTablePagination
        labelDisplayedRows={() => null}
        rowsPerPageOptions={[]}
        count={count}
        rowsPerPage={pagination.limit}
        page={count ? pagination.page - 1 : 0}
        onPageChange={handleChangePage}
        ActionsComponent={(props) => (
          <PaginationActions
            {...props}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      />
    </StyledTableContainer>
  );
};

export default ProjectsTable;
