import React, { FC, useCallback } from 'react';

import Modal from 'molecules/Modal';
import ReportErrorTable from '.';
import { ModalContent } from 'molecules/Modal/Modal.styles';

import { Report } from 'organisms/CloudOpsReportAdmin/types';
import { Stack } from '@mui/material';
import { contractTypeMap } from 'utils/constants/common';
import {
  StyledReportDetails,
  StyledReportDetailsText,
} from './ReportErrorTable.styles';

export const ReportErrorModal: FC<{
  report: Report;
  onClose: () => void;
}> = ({ report, onClose }) => {
  const handleModalClose = useCallback(() => {
    onClose();
    document.body.setAttribute('id', '');
  }, []);

  if (!report) return <span />;

  return (
    <Modal
      open
      maxWidth="lg"
      title="Incorrect billing data details"
      subtitle="This window shows project field errors from Zoho Projects that may block auto-invoicing, with detailed explanations for each issue."
      onClose={handleModalClose}
      sx={{ zIndex: 33333 }}
    >
      <ModalContent
        sx={{
          paddingBottom: '16px !important',
          maxHeight: '400px',
          overflow: 'hidden',
        }}
      >
        <StyledReportDetails>
          <Stack direction="row">
            <StyledReportDetailsText $bold>
              Project name:
            </StyledReportDetailsText>
            <StyledReportDetailsText>
              {report?.project?.name}
            </StyledReportDetailsText>
          </Stack>
          <Stack direction="row">
            <StyledReportDetailsText $bold>
              Contract type:
            </StyledReportDetailsText>
            <StyledReportDetailsText>
              {contractTypeMap[report?.project?.contract_type]}
            </StyledReportDetailsText>
          </Stack>
        </StyledReportDetails>
        {report ? <ReportErrorTable report={report} /> : '...'}
      </ModalContent>
    </Modal>
  );
};
