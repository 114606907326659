import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import { isAnyAdmin, SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { StyledTableWrapper } from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';
import { contractTypeMap, months } from 'utils/constants/common';
import { Report } from 'organisms/CloudOpsReportAdmin/types';
import { Company } from 'pages/CloudOpsReport/types';
import { getCloudOpsReports } from 'store/cloudOps/cloudOpsSlice';
import {
  getInvoiceColor,
  getInvoiceInfo,
  renderRowDetails,
} from 'organisms/CloudOpsReportAdmin/common';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import Table from 'molecules/Table';
import Modal from 'molecules/Modal';
import { ModalContent } from 'molecules/Modal/Modal.styles';
import TimeLogTable from 'molecules/TimeLogTable';
import { StyledTableContainer } from 'molecules/Table/styles';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { ApproveReport } from 'molecules/ApproveReport';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';
import InvoicePreview from 'molecules/InvoicePreview';
import { ReportErrorModal } from 'molecules/ReportErrorTable/ReportErrorModal';

const CloudOpsReportAdminTable: FC<{ company: Company }> = ({ company }) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [link, setLink] = useState<string>('');
  const [invoiceApproveReport, setInvoiceApproveReport] =
    useState<Report | null>(null);
  const [reportErrors, setReportErrors] = useState<Report | null>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeReport, setActiveReport] = useState<number>();

  const [invoicePreview, setInvoicePreview] = useState<string | null>(null);

  const dispatch = useBaoDispatch();
  const {
    user: { value: user },
    cloudOps,
  } = useBaoSelector((state) => state);
  const hsId = String(company.companyHsId);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    fetchPageData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (option: number) => {
    setRowsPerPage(option);
    setCurrentPage(0);
    fetchPageData(0, option);
  };

  const handleApprove = (
    event: SyntheticEvent<HTMLButtonElement>,
    report: Report
  ) => {
    event.stopPropagation();
    setInvoiceApproveReport(report);
  };

  const handleErrorsShow = (report: Report) => {
    document.body.setAttribute('id', 'body');
    setReportErrors(report);
  };

  const columns = useMemo(() => {
    const returnValue = [
      {
        key: 'month',
        label: 'Month',
        sortable: false,
        width: '20%',
        render: ({ month, year }: Report) => (
          <StyledCellText $textType="bold">
            {months[month - 1]}, {year}
          </StyledCellText>
        ),
      },
      {
        key: 'project',
        label: 'Project Name',
        sortable: false,
        width: isAnyAdmin(user?.userType) ? '40%' : '65%',
        render: ({ project }: Report) => (
          <StyledCellText $textType="default">{project.name}</StyledCellText>
        ),
      },
      {
        key: 'contract_type',
        label: 'Contract type',
        sortable: false,
        width: '15%',
        render: ({ project }: Report) => {
          const { contract_type } = project || {};
          return (
            <StyledCellText
              $textType="default"
              $color={contract_type ? neutrals[700] : palette.warning[600]}
            >
              {contract_type
                ? contractTypeMap[contract_type] || contract_type
                : 'Not set'}
            </StyledCellText>
          );
        },
      },
    ];

    if (isAnyAdmin(user?.userType)) {
      returnValue.push({
        key: 'invoice_number',
        label: 'Invoice details',
        sortable: false,
        width: '25%',
        render: (report: Report) => (
          <>
            <StyledCellText
              $color={getInvoiceColor(
                report,
                isSuperAdmin ? (e) => handleApprove(e, report) : undefined
              )}
              sx={{
                display: 'inline',
                // Only apply if invoice_qb_id is exists
                ...(report?.invoice_qb_id
                  ? {
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }
                  : {}),
              }}
              onClick={(event) => {
                event?.preventDefault();
                event?.stopPropagation();

                if (report.invoice_qb_id) {
                  setInvoicePreview(report.invoice_qb_id);
                  document.body.setAttribute('id', 'body');
                }
              }}
            >
              {getInvoiceInfo(
                report,
                isSuperAdmin ? (e) => handleApprove(e, report) : undefined,
                handleErrorsShow
              )}
            </StyledCellText>
          </>
        ),
      });
    }

    return returnValue;
  }, [user?.userType]);

  const handleLogsOpen = useCallback(() => {
    setOpenModal(true);
    document.body.setAttribute('id', 'body');
  }, []);

  const handleLogsClose = useCallback(() => {
    setOpenModal(false);
    document.body.setAttribute('id', '');
  }, []);

  const handleClosePreview = useCallback(() => {
    setInvoicePreview(null);
    document.body.setAttribute('id', '');
  }, []);

  const rowDetailsRenderer = useCallback(
    (row: Report) => renderRowDetails(row, link, handleLogsOpen),
    [link]
  );

  const handleRowClick = useCallback(
    (event, row) => {
      if (window.getSelection()?.type === 'Range') return;

      if (activeReport === row.id) {
        setActiveReport(0);
        setLink('');
      } else {
        if (row.tasks_link) {
          axios
            .get(
              `${process.env.REACT_APP_USER_SERVICE}/company/${row.project.company_hs_id}`
            )
            .then((d) => {
              if (d.data.disable_access_to_zstream === false || isSuperAdmin) {
                setLink(row.tasks_link);
              }
            });
        }
        setActiveReport(row.id);
      }
    },
    [activeReport]
  );

  async function fetchPageData(page: number, limit: number) {
    setLoader(true);
    await dispatch(
      getCloudOpsReports({
        company_hs_id: hsId,
        limit,
        page,
      })
    );
    setLoader(false);
  }

  const activeReportData = cloudOps?.projects_reports?.find(
    ({ id }) => id === activeReport
  );

  return (
    <>
      <StyledTableWrapper>
        <StyledTableContainer sx={{ minWidth: 1000 }}>
          <Table
            idKey="id"
            loading={loader}
            collapsible
            collapsedRow={activeReport}
            columns={columns}
            data={cloudOps?.projects_reports || []}
            onRowClick={handleRowClick}
            rowDetailsRenderer={rowDetailsRenderer}
          />
          <StyledTablePagination
            labelDisplayedRows={() => null}
            rowsPerPageOptions={[]}
            count={cloudOps?.count || 0}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            ActionsComponent={(props) => (
              <PaginationActions
                {...props}
                labelRowsPerPage="Items per page"
                rowsPerPageOptions={[20, 50, 100, 200]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          />
        </StyledTableContainer>
      </StyledTableWrapper>

      {invoicePreview && (
        <InvoicePreview id={invoicePreview} onClose={handleClosePreview} />
      )}

      {invoiceApproveReport && (
        <ApproveReport
          report={invoiceApproveReport}
          onClose={() => setInvoiceApproveReport(null)}
          onAfterClose={() => {
            fetchPageData(currentPage, rowsPerPage);
            setInvoiceApproveReport(null);
          }}
        />
      )}

      {reportErrors && (
        <ReportErrorModal
          report={reportErrors}
          onClose={() => setReportErrors(null)}
        />
      )}

      <Modal
        open={openModal}
        maxWidth="lg"
        title={`${
          activeReportData?.month ? months[activeReportData?.month - 1] : ''
        } time logs details`}
        subtitle={`This window provides comprehensive breakdown of time logs for the ${
          activeReportData?.month ? months[activeReportData?.month - 1] : ''
        }. The details include a list of users, their hourly rates, daily time logs with corresponding amounts, and the total logged hours and amount per user for the month. Additionally, you can download a CSV file containing this information for further analysis or record-keeping.`}
        onClose={handleLogsClose}
        sx={{ zIndex: 33333 }}
      >
        <ModalContent
          sx={{
            paddingBottom: '16px !important',
            maxHeight: '400px',
            overflow: 'hidden',
          }}
        >
          {activeReportData ? (
            <TimeLogTable report={activeReportData} />
          ) : (
            '...'
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CloudOpsReportAdminTable;
