import { Stack, Typography } from '@mui/material';
import { StyledTableCell } from 'molecules/Table/styles';
import styled from 'styled-components';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const StyledReportDetails = styled(Stack)`
  && {
    flex-direction: column;
    gap: 4px;
  }
`;

export const StyledReportDetailsText = styled(Typography)<{
  $bold?: boolean;
}>`
  && {
    font-family: Poppins, sans-serif;
    font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
    font-size: ${({ $bold }) => ($bold ? '11px' : '13px')};
    line-height: 18px;
    letter-spacing: 0%;
    vertical-align: middle;
    text-transform: capitalize;

    color: ${neutrals[700]};

    padding: ${({ $bold }) => ($bold ? '0 8px' : 0)};
    width: ${({ $bold }) => ($bold ? '86px' : 'auto')};
  }
`;

export const ErrorTableHeadCell = styled(StyledTableCell)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 16px;
    letter-spacing: 0%;
    vertical-align: bottom;

    color: ${neutrals[600]};

    border: none !important;

    padding-bottom: 0;
  }
`;

export const ErrorTableCell = styled(StyledTableCell)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0%;
    vertical-align: middle;

    a {
      font-family: Poppins, sans-serif;
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0%;
      vertical-align: middle;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-offset: 0%;
      text-decoration-thickness: 0%;

      color: ${palette.primary.main};
    }

    span {
      font-family: Poppins;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0%;
      vertical-align: middle;

      color: ${palette.error[450]};
    }
  }
`;
