import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { Form } from 'pages/AuthPage/AuthPage.styles';
import {
  validConfirmPassword,
  validPassword,
} from 'utils/helpers/formValidators';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PasswordTextField from 'atoms/PasswordTextField/PasswordTextField';
import {
  PASSWORD_CONFIRM_ERROR_MESSAGE,
  // PASSWORD_ERROR_MESSAGE,
} from 'utils/constants/form';
import Link from 'atoms/Link/Link';
import { palette } from 'utils/styles/variables';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { setUserData } from 'store/user/userSlice';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';

const TemporaryPasswordForm = () => {
  const { width } = useWindowDimensions();

  const dispatch = useBaoDispatch();
  const { value: user } = useBaoSelector((state) => state.user);

  const [form, setForm] = useState({
    password: { value: '', error: '' },
    confirmPassword: { value: '', error: '' },
  });
  const [apiError, setApiError] = useState('');
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const updatedForm = cloneDeep(form);
    if (
      !validConfirmPassword(form.password.value, form.confirmPassword.value)
    ) {
      updatedForm.confirmPassword.error = PASSWORD_CONFIRM_ERROR_MESSAGE;
    }
    // if we have an error don't continue submitting form
    if (
      Boolean(updatedForm.password.error) ||
      Boolean(updatedForm.confirmPassword.error)
    ) {
      setForm(updatedForm);
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE}/update-password`,
        {
          password: form.password.value,
        }
      );
      dispatch(setUserData({ ...user, isTemporaryPassword: false }));
      setIsFinished(true);
      setTimeout(() => {
        setIsFinished(false);
        navigate('/dashboard');
      }, 3000);
    } catch (err) {
      setApiError(err.response.data.message);
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    let { error } = form.password;
    if (validPassword(newPassword)) {
      error = '';
    }

    if (validConfirmPassword(newPassword, form.confirmPassword.value)) {
      form.confirmPassword.error = '';
    }

    setForm((prevState) => ({
      ...prevState,
      password: { ...prevState.password, value: newPassword, error },
    }));
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = e.target.value;
    let { error } = form.confirmPassword;
    if (validConfirmPassword(form.password.value, newConfirmPassword)) {
      error = '';
    }
    setForm((prevState) => ({
      ...prevState,
      confirmPassword: {
        ...prevState.confirmPassword,
        value: newConfirmPassword,
        error,
      },
    }));
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {isFinished && (
        <Alert severity="success">
          Password has been updated successfully!
        </Alert>
      )}
      {apiError && (
        <Alert severity="error">{apiError.replaceAll('_', ' ')}</Alert>
      )}
      <PasswordTextField
        autoComplete="new-password"
        fullWidth
        variant="outlined"
        label="Password *"
        margin="normal"
        error={!!form.password.error}
        helperText={form.password.error}
        onChange={handlePasswordChange}
        InputLabelProps={{ shrink: true }}
      />
      <PasswordTextField
        autoComplete="new-password"
        fullWidth
        variant="outlined"
        label="Confirm Password *"
        margin="normal"
        error={!!form.confirmPassword.error}
        helperText={form.confirmPassword.error}
        onChange={handleConfirmPasswordChange}
        InputLabelProps={{ shrink: true }}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{
          mt: '24px',
          lineHeight: '20px',
          textTransform: 'uppercase',
          borderRadius: '3px',
        }}
      >
        Change password
      </Button>

      {width < 600 ? (
        <Typography
          textAlign={width < 600 ? 'right' : 'center'}
          variant="body1"
          fontSize="0.875rem"
          color="#999999"
          className="form-footer"
        >
          Back to{' '}
          <Link to="/signin" $color={palette.primary.main}>
            Sign in
          </Link>
        </Typography>
      ) : (
        ''
      )}
    </Form>
  );
};

export default TemporaryPasswordForm;
