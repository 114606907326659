import React, { useCallback, useState } from 'react';
import { Tab } from '@mui/material';
import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import { StyledTabList } from 'molecules/Card/Card.styles';
import { StyledTabPanel } from 'organisms/CompanyInvoicesTabs/CompanyInvoicesTabs.styles';
import { TabContext, TabPanel } from '@mui/lab';
import CloudOpsReportPage from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin';
import SalesReportPage from 'organisms/SalesReport';
import { useBaoSelector } from 'utils/hooks/redux';
import { SALES_REP } from 'utils/constants/roles';

type TabType = 'salesRep' | 'autoInvoicing';

const ReportsPage = () => {
  const [selectedTab, setSelectedTab] = useState<TabType>('autoInvoicing');

  const { value: user } = useBaoSelector((state) => state.user);

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: TabType) => {
      setSelectedTab(newValue);
    },
    []
  );

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  if (user?.userType === SALES_REP) {
    return (
      <HeadlessPageWrapper>
        <StyledPaper>
          <SalesReportPage />
        </StyledPaper>
      </HeadlessPageWrapper>
    );
  }

  return (
    <HeadlessPageWrapper>
      <StyledPaper>
        <TabContext value={selectedTab}>
          <StyledTabList
            onChange={handleChangeTabs}
            aria-label="Auto-Invoicing and Sales Reports tabs"
            variant="fullWidth"
          >
            <Tab
              label="Auto-Invoicing"
              {...a11yProps(1)}
              className={selectedTab === 'autoInvoicing' ? '.active' : ''}
              value="autoInvoicing"
            />
            <Tab
              label="Sales Rep"
              {...a11yProps(0)}
              className={selectedTab === 'salesRep' ? '.active' : ''}
              value="salesRep"
            />
          </StyledTabList>
          <StyledTabPanel value="autoInvoicing" sx={{ p: 0 }}>
            <CloudOpsReportPage />
          </StyledTabPanel>
          <TabPanel value="salesRep" sx={{ p: 0 }}>
            <SalesReportPage />
          </TabPanel>
        </TabContext>
      </StyledPaper>
    </HeadlessPageWrapper>
  );
};

export default ReportsPage;
