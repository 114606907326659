import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';

export const StyledLoadingButton = styled(LoadingButton)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 16px;
    letter-spacing: 0%;
    text-decoration: underline;

    padding: 0;
    margin-bottom: 8px;
    align-self: end;

    &:hover {
      text-decoration: none;
      background: transparent;
    }
  }
`;
