import { CloudOpsProjectsType, FormType } from 'pages/CompanyPage/types';

export const invoiceInitialDetails: FormType = {
  minimum_monthly_budget: '0',
  monthly_budget_in_hours: 0,
  over_budget_hourly_rate: '0',
};

export const projectInitialData: CloudOpsProjectsType = {
  id: 0,
  id_str: '',
  key: '',
  type: '',
  name: '',
  status: '',
  description: '',
  company_name: '',
  company_hs_id: 0,
  zstream_project_url: '',
  // Quickbooks fields
  quickbook_project_id: '',
  quickbook_project_name: '',
  parent_company_quickbook_id: '',
  // Invoice details fields
  minimum_monthly_budget: '',
  monthly_budget_in_hours: '',
  over_budget_hourly_rate: '',
  // TODO: 18.01 - check if it's correct?
  completion_date: '',
  contract_type: '',
  automatic_invoice_creation: false,
  invoice_creation_approval_required: false,
};

// checks if project's invoice details empty
export const checkIsProjectInvoiceDetailsEmpty = (
  prj: CloudOpsProjectsType
): boolean => {
  const {
    monthly_budget_in_hours,
    minimum_monthly_budget,
    over_budget_hourly_rate,
  } = prj;

  return !(
    !monthly_budget_in_hours ||
    !minimum_monthly_budget ||
    !over_budget_hourly_rate
  );
};
