import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Alert, CircularProgress, Stack } from '@mui/material';
import { validEmail } from 'utils/helpers/formValidators';
import { Form, StyledAuthTextField } from 'pages/AuthPage/AuthPage.styles';
import { palette } from 'utils/styles/variables';
import Link from 'atoms/Link/Link';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { AlertContainer2 } from 'utils/styles/AlertWrapper';

type FormProps = {
  companyName: { value: string; error: string };
  email: { value: string; error: string };
  firstName: { value: string; error: string };
  lastName: { value: string; error: string };
};

const RequestForm = () => {
  const [form, setForm] = useState<FormProps>({
    companyName: { value: '', error: '' },
    email: { value: '', error: '' },
    firstName: { value: '', error: '' },
    lastName: { value: '', error: '' },
  });
  const [apiResponse, setApiResponse] = useState({
    value: '',
    submitted: false,
    error: false,
  });
  const [checkingEmail, setCheckingEmail] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const updatedForm = cloneDeep(form);
    let error = false;
    if (!validEmail(form.email.value)) {
      updatedForm.email.error = 'Please enter valid email address!';
      error = true;
    }
    if (!form.companyName.value || !form.companyName.value.trim()) {
      updatedForm.companyName.error = 'Please enter Company Name';
      error = true;
    }
    if (!form.firstName.value || !form.firstName.value.trim()) {
      updatedForm.firstName.error = 'Please enter your first name';
      error = true;
    }
    if (!form.lastName.value || !form.lastName.value.trim()) {
      updatedForm.lastName.error = 'Please enter your last name';
      error = true;
    }
    setForm(updatedForm);
    if (error) {
      return false;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/request-account`,
        {
          company_name: form.companyName.value,
          email: form.email.value,
          first_name: form.firstName.value,
          last_name: form.lastName.value,
        }
      );

      return setApiResponse({
        value: response.data.message,
        error: false,
        submitted: true,
      });
    } catch (err) {
      return setApiResponse({
        value: err.response.data.message,
        submitted: false,
        error: true,
      });
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof FormProps
  ) => {
    const updatedForm = cloneDeep(form);
    const newValue = e.target.value;
    if (field === 'email') {
      if (validEmail(newValue)) {
        updatedForm.email.error = '';
      }
    } else if (field === 'companyName') {
      if (newValue) {
        updatedForm.companyName.error = '';
      }
    } else if (field === 'firstName') {
      if (newValue) {
        updatedForm.firstName.error = '';
      }
    } else if (field === 'lastName') {
      if (newValue) {
        updatedForm.lastName.error = '';
      }
    }
    updatedForm[field].value = newValue;
    setForm(updatedForm);
  };

  const submitCheckEmail = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const email = e.target?.value;
    const updatedForm = cloneDeep(form);

    if (email) {
      if (!validEmail(form.email.value)) {
        updatedForm.email.error = 'Please enter valid email address!';
        setForm(updatedForm);
      }

      setCheckingEmail(true);

      try {
        const encodedEmail = encodeURIComponent(email);
        const response = await axios.get(
          `${process.env.REACT_APP_USER_SERVICE}/get?email=${encodedEmail}`
        );

        if (response.status === 200) {
          updatedForm.email.error =
            'Account with provided email already exists in Zazmic-Connect!';
          setForm(updatedForm);
        }

        setCheckingEmail(false);
        return null;
      } catch (err) {
        setCheckingEmail(false);

        if (err.response.status === 404) {
          return setApiResponse({
            value: '',
            submitted: false,
            error: false,
          });
        }

        return setApiResponse({
          value: 'Error checking e-mail',
          submitted: false,
          error: true,
        });
      }
    }

    return null;
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {apiResponse.value && !apiResponse.error ? (
        <AlertContainer2
          sx={{
            py: '16px',
            '.MuiPaper-root': {
              p: '8px !important',
              borderRadius: '4px !important',
              justifyContent: 'start !important',
            },
            '.MuiAlert-message': { textTransform: 'unset !important' },
          }}
        >
          <Alert severity="success">{apiResponse?.value}</Alert>
        </AlertContainer2>
      ) : (
        <>
          <StyledAuthTextField
            disabled={apiResponse.submitted}
            error={!!form.companyName.error}
            autoComplete="organization"
            fullWidth
            variant="outlined"
            label="Company name *"
            margin="normal"
            onChange={(e) => handleChange(e, 'companyName')}
            helperText={form.companyName.error}
            value={form.companyName.value}
            InputLabelProps={{ shrink: true }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StyledAuthTextField
              disabled={apiResponse.submitted || checkingEmail}
              error={!!form.email.error}
              autoComplete="email"
              fullWidth
              variant="outlined"
              label="Email *"
              margin="normal"
              type="email"
              onChange={(e) => handleChange(e, 'email')}
              helperText={form.email.error}
              value={form.email.value}
              onBlur={submitCheckEmail}
              InputLabelProps={{ shrink: true }}
            />
            {checkingEmail && (
              <CircularProgress
                size={24}
                sx={{
                  marginLeft: '10px',
                }}
              />
            )}
          </div>
          <StyledAuthTextField
            disabled={apiResponse.submitted}
            error={!!form.firstName.error}
            autoComplete="given-name"
            fullWidth
            variant="outlined"
            label="First name *"
            margin="normal"
            onChange={(e) => handleChange(e, 'firstName')}
            helperText={form.firstName.error}
            value={form.firstName.value}
            InputLabelProps={{ shrink: true }}
          />
          <StyledAuthTextField
            disabled={apiResponse.submitted}
            error={!!form.lastName.error}
            autoComplete="family-name"
            fullWidth
            variant="outlined"
            label="Last name *"
            margin="normal"
            onChange={(e) => handleChange(e, 'lastName')}
            helperText={form.lastName.error}
            value={form.lastName.value}
            InputLabelProps={{ shrink: true }}
          />
          {!apiResponse.submitted && (
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                margin: '24px 0 16px',
                lineHeight: '20px',
                textTransform: 'uppercase',
                borderRadius: '3px',
              }}
            >
              Request an account
            </Button>
          )}
        </>
      )}

      <Stack alignItems="end" mt="16px">
        <Link
          to="/signin"
          $fontWeight={500}
          $fontSize="0.75rem"
          $lineHeight="18px"
          $color={palette.primary.main}
        >
          Back to log in page
        </Link>
      </Stack>
    </Form>
  );
};

export default RequestForm;
