import React, { FC, useContext, useEffect, useState } from 'react';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateRange } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import {
  StyledDateLabel,
  StyledDateRangeFilterAction,
  StyledDateRangeFilterHeader,
  StyledPresetHeader,
} from 'organisms/Filter/forms/Form.styles';
import { MonthPicker } from 'molecules/Calendar/MonthRangePicker/MonthPicker';
import { neutrals } from 'utils/styles/color';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import { FilterContext } from 'organisms/Filter/index';

const actionButtons = [
  { code: 'current_month', title: 'Current Month' },
  { code: 'previous_month', title: 'Previous Month' },
  { code: 'current_quarter', title: 'Current Quarter' },
  { code: 'previous_quarter', title: 'Previous Quarter' },
  { code: 'current_year', title: 'Current Year' },
  { code: 'previous_year', title: 'Previous Year' },
];

const MonthPickerFilter: FC<{ name: string }> = ({ name }) => {
  const context = useContext(FilterContext);
  const { options, filter, setFilter, setOptions } = context;

  const [active, setActive] = useState<
    (typeof actionButtons)[number]['code'] | undefined
  >();
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const [selection, setSelection] = React.useState<'from' | 'to' | null>(null);

  dayjs.extend(quarterOfYear);
  dayjs.extend(isBetween);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  const onChange = (newValue: DateRange<Date>) => {
    setValue(newValue);
    setFilter({ [name]: newValue[0] && newValue[1] ? newValue : undefined });
  };

  useEffect(() => {
    if (filter[name]) {
      setValue([
        dayjs((filter[name] as DateRange<Date>)[0] as Date).toDate(),
        dayjs((filter[name] as DateRange<Date>)[1] as Date).toDate(),
      ]);

      if (options.find((item) => item.code === name)) {
        setActive(options.find((item) => item.code === name)?.activeItem);
      }
    }
  }, []);

  const handleAction = (code: (typeof actionButtons)[number]['code']) => {
    setSelection(null);
    setActive(code);
    setOptions(
      options.map((item) =>
        item.code === name ? { ...item, activeItem: code } : item
      )
    );

    switch (code) {
      case 'current_month':
        onChange([
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ]);
        break;
      case 'previous_month':
        onChange([
          dayjs().subtract(1, 'month').startOf('month').toDate(),
          dayjs().subtract(1, 'month').endOf('month').toDate(),
        ]);
        break;
      case 'current_quarter':
        onChange([
          dayjs().startOf('quarter').toDate(),
          dayjs().endOf('quarter').toDate(),
        ]);
        break;
      case 'previous_quarter':
        onChange([
          dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
          dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
        ]);
        break;
      case 'current_year':
        onChange([
          dayjs().startOf('year').toDate(),
          dayjs().endOf('year').toDate(),
        ]);
        break;
      case 'previous_year':
        onChange([
          dayjs().subtract(1, 'year').startOf('year').toDate(),
          dayjs().subtract(1, 'year').endOf('year').toDate(),
        ]);
        break;
      default:
        onChange([null, null]);
        break;
    }
  };

  if (!context) {
    throw new Error('DatePickerFilter must be used within a FilterProvider');
  }

  const selectedDate = selection === 'from' ? value[0] : value[1];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack>
        <StyledDateRangeFilterHeader>
          <Stack>
            <StyledDateLabel
              $active={selection === 'from'}
              onClick={() => setSelection('from')}
            >
              <Typography
                component="span"
                sx={{ color: `${neutrals[value[0] ? 700 : 400]} !important` }}
              >
                {value[0]
                  ? dayjs(value[0])?.format('YYYY-MM-DD')
                  : 'Start date'}
              </Typography>
            </StyledDateLabel>
            <Typography component="span">/</Typography>
            <StyledDateLabel
              $active={selection === 'to'}
              onClick={() => setSelection('to')}
            >
              <Typography
                component="span"
                sx={{ color: `${neutrals[value[1] ? 700 : 400]} !important` }}
              >
                {value[1] ? dayjs(value[1])?.format('YYYY-MM-DD') : 'End date'}
              </Typography>
            </StyledDateLabel>
          </Stack>
          <Button
            variant="text"
            disableRipple
            onClick={() => {
              setSelection(null);
              onChange([null, null]);
            }}
          >
            Reset
          </Button>
        </StyledDateRangeFilterHeader>

        <Stack direction="row">
          <Stack sx={{ pb: '8px', minWidth: 144, position: 'relative' }}>
            <StyledPresetHeader>
              <Typography component="span">Presets</Typography>
            </StyledPresetHeader>
            {actionButtons.map((item) => (
              <StyledDateRangeFilterAction
                key={item.code}
                $active={active === item.code}
                onClick={() => handleAction(item.code)}
              >
                {item.title}
              </StyledDateRangeFilterAction>
            ))}
          </Stack>
          {selection !== null && (
            <MonthPicker
              value={selectedDate}
              onSelect={(newDate: Date) => {
                if (!newDate) return;

                if (selection === 'from') {
                  onChange([
                    dayjs(newDate).startOf('month').toDate(),
                    value[1] || dayjs(newDate).endOf('month').toDate(),
                  ]);
                }
                if (selection === 'to') {
                  onChange([
                    value[0] || dayjs(newDate).startOf('month').toDate(),
                    dayjs(newDate).endOf('month').toDate(),
                  ]);
                }
                if (active) setActive(undefined);
                setSelection(null);
              }}
              shouldDisableDate={(date: Date) => {
                if (dayjs(value[0]).isSame(dayjs(value[1]), 'month'))
                  return false;

                if (selection === 'from') {
                  return dayjs(date).isAfter(dayjs(value[1]));
                }
                if (selection === 'to') {
                  return dayjs(date).isBefore(dayjs(value[0]));
                }
                return false;
              }}
            />
          )}
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default MonthPickerFilter;
