import React, { FC } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useBaoSelector } from 'utils/hooks/redux';
import { SALES_REP, SUPER_ADMIN } from 'utils/constants/roles';

const authPaths = [
  'create-password',
  'request-account',
  'signin',
  'forgot-password',
  'verification',
  'invoice-payment',
  'contact-us',
];
const commonPaths = [
  'dashboard',
  'invoices',
  'invoice-details',
  'invoice-payment',
  'mobile-payment',
  'invoice',
  'account',
  'coreports',
  'redirect',
  'temporary-password',
];
const companyAdminPaths = ['payment'];
const salesRepPaths = ['coreports', 'account', 'company', 'invoices'];
const superAdminPaths = [
  'settings',
  'companies',
  'contacts',
  'company',
  'coreports',
  'logs',
  'sales-report',
  'projects',
];

const ProtectedRoute: FC<{ children: JSX.Element }> = ({ children }) => {
  let { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { value: user } = useBaoSelector((state) => state.user);
  const userType =
    user.userType || JSON.parse(localStorage.getItem('user') || '{}').userType;

  const path = pathname.split('/')[1];

  // If path from authPaths list but user already loggedIn
  if (authPaths.includes(path) && userType) {
    const redirectPath = searchParams.get('redirect');
    return <Navigate to={redirectPath || '/dashboard'} replace />;
  }

  // If path from authPaths list and user doesn't loggedIn
  if (authPaths.includes(path) && !userType) return children;

  if (!userType) {
    if (searchParams.get('company_hs_id')) {
      pathname = `${pathname}?company_hs_id=${searchParams.get(
        'company_hs_id'
      )}`;
    }
    return <Navigate to={`/signin?redirect=${pathname}`} replace />;
  }

  if (user.isTemporaryPassword && path !== 'temporary-password') {
    return <Navigate to="/temporary-password" replace />;
  }

  if (!user.isTemporaryPassword && path === 'temporary-password') {
    return <Navigate to="/dashboard" replace />;
  }

  if (userType === SALES_REP && !salesRepPaths.includes(path)) {
    return <Navigate to="/coreports" replace />;
  }

  if (
    userType === SUPER_ADMIN &&
    ![...superAdminPaths, ...commonPaths].includes(path)
  ) {
    return <Navigate to="/dashboard" replace />;
  }

  if (
    userType === 'company_admin' &&
    ![...companyAdminPaths, ...commonPaths].includes(path)
  ) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default ProtectedRoute;
