import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorsNeutrals, neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const StyledFilterTitle = styled(Box)`
  && {
    padding: 8px 16px;
    height: 24px;
    display: flex;
    align-items: center;
    background-color: ${neutrals[100]};

    span {
      color: ${colorsNeutrals[800]};
      font-family: 'Poppins', sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
`;

export const StyledFilterRow = styled(Box)<{
  $active?: boolean;
}>`
  && {
    display: flex;
    height: 24px;
    padding: 0 8px 0 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    position: relative;

    span {
      padding: 0;
      color: ${colorsNeutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }

    &:hover {
      span {
        color: ${palette.primary.main};
      }
    }

    ${({ $active }) =>
      $active &&
      `
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 3px;
          height: 10px;
          border-radius: var(--none, 0px) 1.5px 1.5px var(--none, 0px);
          background: ${colorsNeutrals[500]};
        }
        span {
          color: ${colorsNeutrals[500]} !important;
          text-decoration-line: underline;
        }
      `}
  }
`;
