import React, { FC } from 'react';
import Modal from 'molecules/Modal';
import { Stack } from '@mui/material';
import { palette } from 'utils/styles/variables';

const CloudOpsProjectInfoDialog: FC<{
  onClose?: () => void;
}> = ({ onClose }) => (
  <Modal
    open
    maxWidth="sm"
    padding24
    title="Invoicing automation guide"
    transitionDuration={0}
    subtitle={
      <Stack gap={2} pb="24px">
        <span>
          <b
            style={{
              fontWeight: 400,
              color: palette.success[500],
            }}
          >
            ON
          </b>
          :<br /> Zazmic-Connect will generate monthly reports based on the
          contract terms retrieved from Zoho Projects and automatically create
          an invoice on the 1st day of the following month. For example, the
          July report will result in an invoice being created on August 1st.
        </span>
        <span>
          <b
            style={{
              fontWeight: 400,
              color: palette.warning[600],
            }}
          >
            OFF
          </b>
          :<br /> Zazmic-Connect will stop generating monthly reports and
          creating invoices.
        </span>
      </Stack>
    }
    onClose={onClose}
  >
    <></>
  </Modal>
);

export default CloudOpsProjectInfoDialog;
