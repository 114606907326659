import { companyType } from 'pages/CompanyPage/types';

export const initialState: companyType = {
  address: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  name: '',
  email: '',
  domain: '',
  gcpBillingId: '',
  companyHsId: '',
  lifetime_billings: 0,
  since: '',
  past_due: '',
  auto_pay: false,
  total_due: 0,
  paymentTerms: '',
  total_billing_amount: 0,
  discount: 0,
  createSoftwareDevInvoices: false,
  sendEmails: false,
  disableAccessToZstream: false,
  disablePastDueInvoiceEmails: false,
  disableBudgetExceedEmail: false,
  disableGcpInvoiceCreation: false,
  hsCompanyLink: '',
  qbCompanyLink: '',
  currency: '',
  qbRegion: '',
  vat: '',
};
