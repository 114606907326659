import React, { FC, useState } from 'react';
import Modal from 'molecules/Modal';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import axios from 'axios';
import { Alert } from '@mui/material';

const CloudOpsProjectInvoiceCreationDialog: FC<{
  project: CloudOpsProjectsType;
  onClose: () => void;
  onAfterClose: () => void;
}> = ({ onClose, onAfterClose, project }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${project?.id_str}`,
        {
          invoice_creation_approval_required:
            project?.invoice_creation_approval_required !== true,
        }
      );

      onAfterClose();
    } catch (err) {
      setAlert({ error: true, message: err.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      transitionDuration={0}
      title={
        project?.invoice_creation_approval_required
          ? 'Switch OFF invoice creation approval'
          : 'Switch ON invoice creation approval'
      }
      subtitle={
        project?.invoice_creation_approval_required
          ? 'When this feature is turned OFF, invoices will be created automatically without requiring approval for the record on Auto-invoicing report. Use this setting to align the approval process with your project’s requirements.'
          : 'When this feature is turned ON, invoice creation for this project will require approval on Auto-invoicing report. '
      }
      onClose={onClose}
    >
      <>
        {alert.message ? (
          <ModalContent>
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          </ModalContent>
        ) : undefined}
        <ModalActions>
          <ModalCloseButton
            disabled={loading}
            autoFocus
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            loading={loading}
            variant="contained"
            onClick={onSubmit}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectInvoiceCreationDialog;
