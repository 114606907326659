import React from 'react';
import { Container, Typography } from '@mui/material';
import { FlexBox, FooterComp } from './Footer.styles';
import { neutrals } from 'utils/styles/color';

const Footer = () => (
  <FooterComp>
    <Container>
      <FlexBox>
        <Typography
          sx={{
            color: `${neutrals[400]} !important`,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.75rem !important',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15.5px',
          }}
        >
          © {new Date().getFullYear()} Zazmic, Inc.
        </Typography>
      </FlexBox>
    </Container>
  </FooterComp>
);

export default Footer;
