import axios from 'axios';
import { logout, setUserState } from 'store/user/userSlice';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { useEffect, useRef, useState } from 'react';

const useInterceptors = () => {
  const dispatch = useBaoDispatch();
  const { value: user } = useBaoSelector((state) => state.user);
  const requestInterceptorsRef = useRef(0);
  const responseInterceptorsRef = useRef(0);
  const [interceptorsCompleted, setInterceptorsCompleted] = useState(false);

  useEffect(() => {
    const interceptors = async () => {
      // cleanup previous request interceptors
      if (requestInterceptorsRef.current) {
        axios.interceptors.request.eject(requestInterceptorsRef.current);
      }
      // set new request interceptors
      requestInterceptorsRef.current = await axios.interceptors.request.use(
        (config) => {
          if (config.headers && user.accessToken) {
            config.headers.Authorization = user.accessToken;
          }
          return config;
        }
      );
      // cleanup previous response interceptors
      if (responseInterceptorsRef.current) {
        axios.interceptors.response.eject(responseInterceptorsRef.current);
      }
      // set new response interceptors
      responseInterceptorsRef.current = await axios.interceptors.response.use(
        (config) => config,
        // if (config?.config.url?.includes(process.env.REACT_APP_BILLING_SERVICE as string)) {
        //   httpLogger([`${user.email} got result from endpoint: ${config?.config.url}: ${JSON.stringify(config?.data)}`]);
        // }
        async (error) => {
          if (error.response?.status === 401) {
            // if we have refresh token regain access token and try again
            if (user.refreshToken) {
              const response = await axios.post(
                `${process.env.REACT_APP_USER_SERVICE}/refresh-token`,
                {
                  refresh_token: user.refreshToken,
                }
              );
              dispatch(setUserState(response));
              error.config.headers.Authorization = response.data.access_token;
              return axios.request(error.config);
            }
            // otherwise, logout the user out
            dispatch(logout());
          }
          return Promise.reject(error);
        }
      );
    };
    interceptors()
      .then(() => setInterceptorsCompleted(true))
      .catch(() => setInterceptorsCompleted(false));
  }, [user.accessToken, user.refreshToken, dispatch]);
  return interceptorsCompleted;
};

export default useInterceptors;
