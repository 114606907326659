export const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'code',
});

export const currencyFormatter = (amount: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.trim() || 'USD',
    currencyDisplay: 'code',
  });

  if (!currency) return '';

  return formatter.format(amount);
};

export const oneDigitCurrencyFormatter = (
  amount: number,
  currency?: string
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.trim() || 'USD',
    currencyDisplay: 'code',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  if (!currency) return '';

  const truncatedValue = Math.trunc(amount * 100) / 100;

  return formatter.format(truncatedValue);
};

export const currency = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currencyDisplay: 'code',
});

export const formatLargeNumber = (num: number, currencyName: string) => {
  // Suffixes for thousands, millions, billions, trillions, etc.
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  // const prefixes = { USD: '$', EUR: '€', GBP: '£', CAD: '$' };
  // const prefix = prefixes[currencyName as keyof typeof prefixes];

  if (!num) return '0';

  // Return the formatted number as string if less than 1 million
  if (num < 1000) return `${num}`;

  // Determine the appropriate suffix
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < suffixes.length; i++) {
    if (num < 1000 ** (i + 1)) {
      const formattedNum = num / 1000 ** i;
      if (Number.isInteger(formattedNum)) {
        return `${formattedNum}${suffixes[i]}`;
      }

      return `${formattedNum.toFixed(1)}${suffixes[i]}`;
    }
  }

  return currencyFormatter(num, currencyName); // Fallback, though it should never reach this point with the given suffix list
};
