import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import { StyledTableCell } from 'molecules/Table/styles';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const TimeTableHeadCell = styled(StyledTableCell)<{
  $borderRight?: boolean;
}>`
  && {
    padding: 8px;
    min-width: 48px;
    width: 48px;
    max-width: 48px;
    border-right: ${({ $borderRight }) =>
      $borderRight ? `2px solid ${neutrals[100]}` : 'none'};
    span {
      font-family: Poppins, sans-serif;
      font-weight: 300;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: ${neutrals[600]};
    }
    span:first-of-type {
      font-size: 0.75rem;
      line-height: 16px;
    }
    span:last-of-type {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

export const TimeTableCell = styled(StyledTableCell)<{
  $warning?: boolean;
  $borderRight?: boolean;
}>`
  && {
    padding: 8px;
    border-right: ${({ $borderRight }) =>
      $borderRight ? `2px solid ${neutrals[100]}` : 'none'};
    span {
      font-family: Poppins, sans-serif;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: ${({ $warning }) =>
        $warning ? palette.warning[600] : neutrals[700]};
    }
    span:first-of-type {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    span:last-of-type {
      font-size: 11px;
      font-weight: 300;
      line-height: 14px;
    }
  }
`;

export const StyledFilterWrapper = styled(Stack)`
  && {
    height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const StyledTotalPerDay = styled('span')<{
  $color: string;
}>`
  && {
    color: ${({ $color }) => `${$color} !important`};
  }
`;
