import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import FilterOptions from 'organisms/Filter/components/FilterOptions';
import DatePickerFilter from 'organisms/Filter/forms/DatePickerFilter';
import MonthPickerFilter from 'organisms/Filter/forms/MonthPickerFilter';
import ListFilter from 'organisms/Filter/forms/ListFilter';
import { Popover, IconButton, Stack, Box, Tooltip } from '@mui/material';

import { neutrals } from 'utils/styles/color';
import {
  IFilterContext,
  IFilterOption,
  IFilterProps,
} from 'organisms/Filter/types';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export const FilterContext = createContext<IFilterContext>({
  filter: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFilter: (c) => c,
  options: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOptions: (o) => o,
});

const Filter: FC<IFilterProps> = ({ options, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterOptions, setFilterOptions] = useState<IFilterOption[]>([]);
  const [filter, setFilter] = useState({});

  const boxRef = useRef(null);

  const onFilterChange = useCallback(
    (nextFilter: { [key: string]: unknown }) => {
      setFilter((prev) => {
        onChange({ ...prev, ...nextFilter });
        return { ...prev, ...nextFilter };
      });
    },
    []
  );

  useEffect(() => {
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    onChange(filter);
  }, [filter]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    document.body.setAttribute('id', 'body');
  };

  const handleReset = () => {
    setFilter({});
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.body.setAttribute('id', '');
  };

  const isFilled = (() => {
    if (!filter) return false;
    return !Object.values(filter).every((value) => {
      if (Array.isArray(value) && value.length === 0) return true;
      if (typeof value === 'string' && value === '') return true;
      return value === undefined;
    });
  })();

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;
  const activeOption = filterOptions.find((o) => o.active);

  // eslint-disable-next-line
  console.log(filter);

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter: onFilterChange,
        options: filterOptions,
        setOptions: setFilterOptions,
      }}
    >
      <div>
        <IconButton
          sx={{ p: 0 }}
          onClick={handleClick}
          aria-describedby={id}
          color="primary"
          aria-label="Filter data"
          component="span"
        >
          <FilterIcon />
        </IconButton>
        {isFilled && (
          <Tooltip title="Reset all applied filters">
            <IconButton
              sx={{ p: 0, ml: 1 }}
              onClick={handleReset}
              color="primary"
              aria-label="Reset filter"
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          container={boxRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            position: 'unset',
          }}
          PaperProps={{
            sx: {
              overflowY: 'hidden',
              minWidth: '600px !important',
              width: 600,
              height: '362px',
              minHeight: '362px',
            },
          }}
        >
          <Stack direction="row" sx={{ height: 362 }}>
            <Box
              sx={{
                width: 184,
                borderRight: `1px solid ${neutrals[100]}`,
              }}
            >
              <FilterOptions />
            </Box>
            <Box sx={{ width: 416 }}>
              {activeOption?.code === 'invoice_date' && (
                <DatePickerFilter name="invoice_date" />
              )}
              {activeOption?.code === 'completion_date' && (
                <DatePickerFilter name="completion_date" />
              )}
              {activeOption?.code === 'sent_date' && (
                <DatePickerFilter name="sent_date" />
              )}
              {activeOption?.code === 'date' && (
                <MonthPickerFilter name="date" />
              )}
              {activeOption?.code === 'invoice_automation' && (
                <ListFilter name="invoice_automation" />
              )}
              {activeOption?.code === 'invoice_creation_approval' && (
                <ListFilter name="invoice_creation_approval" />
              )}
              {activeOption?.code === 'contract_type' && (
                <ListFilter name="contract_type" />
              )}
              {activeOption?.code === 'status' && <ListFilter name="status" />}
              {activeOption?.code === 'sales_rep' && (
                <ListFilter name="sales_rep" />
              )}
              {activeOption?.code === 'company' && (
                <ListFilter name="company" />
              )}
              {activeOption?.code === 'accountant' && (
                <ListFilter name="accountant" />
              )}
              {activeOption?.code === 'invoice_status' && (
                <ListFilter name="invoice_status" />
              )}
              {activeOption?.code === 'subject' && (
                <ListFilter name="subject" />
              )}
              {activeOption?.code === 'close_date' && (
                <DatePickerFilter name="close_date" />
              )}
            </Box>
          </Stack>
        </Popover>

        {/* Container for menu paper */}
        <Box
          className="filter-popover-container"
          sx={{
            display: open ? 'block' : 'none',
            width: '654px',
            height: '393px',
            position: 'absolute',
            top: '40px',
            left: '5px',
          }}
        >
          <Box
            ref={boxRef}
            sx={{
              zIndex: 10,
              position: 'relative',
              width: '100%',
              height: '100%',
              '.MuiPaper-root': {
                top: '10px !important',
                left: `14px !important`,
              },
            }}
          />
        </Box>
      </div>
    </FilterContext.Provider>
  );
};

export default Filter;
