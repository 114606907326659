import React, { FC } from 'react';
import Modal from 'molecules/Modal';
import { Stack } from '@mui/material';
import { palette } from 'utils/styles/variables';

const CloudOpsProjectInvoiceCreationInfoDialog: FC<{
  onClose?: () => void;
}> = ({ onClose }) => (
  <Modal
    open
    maxWidth="sm"
    padding24
    title="Invoice creation approval"
    transitionDuration={0}
    subtitle={
      <Stack gap={2} pb="24px">
        <span>
          <b
            style={{
              fontWeight: 400,
              color: palette.success[500],
            }}
          >
            ON
          </b>
          :<br /> When this feature is turned ON, invoice creation for this
          project will require approval on Auto-invoicing report.
        </span>
        <span>
          <b
            style={{
              fontWeight: 400,
              color: palette.warning[600],
            }}
          >
            OFF
          </b>
          :<br /> When this feature is turned OFF, invoices will be created
          automatically without requiring approval for the record on
          Auto-invoicing report. Use this setting to align the approval process
          with your project’s requirements.
        </span>
      </Stack>
    }
    onClose={onClose}
  >
    <></>
  </Modal>
);

export default CloudOpsProjectInvoiceCreationInfoDialog;
