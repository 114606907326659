import React, { FC, useState } from 'react';
import Modal from 'molecules/Modal';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import axios from 'axios';
import { Alert } from '@mui/material';

const CloudOpsProjectInvoiceCreationDialog: FC<{
  project: CloudOpsProjectsType;
  onClose: () => void;
  onAfterClose: () => void;
}> = ({ onClose, onAfterClose, project }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${project?.id_str}`,
        {
          automatic_invoice_creation:
            project?.automatic_invoice_creation !== true,
        }
      );

      onAfterClose();
    } catch (err) {
      setAlert({ error: true, message: err.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      transitionDuration={0}
      title={
        project?.automatic_invoice_creation
          ? 'Switch OFF project invoicing automation'
          : 'Switch ON project invoicing automation'
      }
      subtitle={
        project?.automatic_invoice_creation
          ? 'Project will be deactivated and Zazmic-Connect will stop generating monthly reports. Current month report will be deleted. Previous months reports will stay.'
          : 'If you activate a project during a day Zazmic-Connect will generate monthly reports based on the contract terms retrieved from Zoho Projects  and automatically create an invoice on the 1st day of the following month. For example, for a July report, the invoice will be created from August 5th based on the approved logged time.'
      }
      onClose={onClose}
    >
      <>
        {alert.message ? (
          <ModalContent>
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          </ModalContent>
        ) : undefined}
        <ModalActions>
          <ModalCloseButton
            disabled={loading}
            autoFocus
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            loading={loading}
            variant="contained"
            onClick={onSubmit}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectInvoiceCreationDialog;
