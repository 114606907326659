import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Stack,
} from '@mui/material';

import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { oneDigitCurrencyFormatter } from 'utils/helpers/currencyFormatter';
import {
  TimeTableHeadCell,
  TimeTableCell,
  StyledFilterWrapper,
  StyledTotalPerDay,
} from 'molecules/TimeLogTable/TimeLogTable.styles';
import { neutrals } from 'utils/styles/color';
import { ExportCSVButton } from 'organisms/SalesReport/SalesReport.styles';
import { Report } from 'organisms/CloudOpsReportAdmin/types';
import { CONTRACT_TYPE } from 'utils/constants/common';
import loaderGif from 'assets/images/loaderGif.gif';
import { formatHours } from 'utils/helpers/dates';
import { StyledTableCell } from 'molecules/Table/styles';
import { palette } from 'utils/styles/variables';
import { getFilenameCSVFromHeader } from 'organisms/InvoicesTable/helpers';

type ILogItem = {
  date: string;
  hours: number | null;
  approved: boolean;
  amount_per_day: number | null;
};
export interface ITimeLogTableRow {
  daily_rate: number;
  hourly_rate: number;
  task_list: ILogItem[];
  total_fee: number;
  total_hours: number;
  user_id: number;
  user_name: string;
}
export interface ITimeLogTableProps {
  report: Report;
}

const TimeLogTable: FC<ITimeLogTableProps> = ({ report }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITimeLogTableRow[]>([]);

  const { id, project, currency, year, month } = report || {};

  const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();

  const handleExportCsv = useCallback(async () => {
    try {
      setExportLoading(true);
      const response: AxiosResponse<string> = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/project/report-timelogs`,
        {
          params: {
            id,
            export_csv: true,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      // Create a Blob from the CSV data
      const blob = new Blob([response?.data], { type: 'text/csv' });

      const filename = getFilenameCSVFromHeader(response);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    } finally {
      setExportLoading(false);
    }
  }, []);

  const getRateDetails = (row: ITimeLogTableRow) => {
    if (project?.contract_type === CONTRACT_TYPE.PRORATED_FIXED_PRICE) {
      return `${oneDigitCurrencyFormatter(
        Number(row.daily_rate),
        currency
      )}/day`;
    }

    if (
      [
        CONTRACT_TYPE.MIXED_PRICE,
        CONTRACT_TYPE.TIME_AND_MATERIAL,
        CONTRACT_TYPE.TIME_AND_MATERIAL_AT_COST,
      ].includes(project?.contract_type)
    ) {
      return `${oneDigitCurrencyFormatter(
        Number(row.hourly_rate),
        currency
      )}/hour`;
    }

    return '';
  };

  const getRateDetailsColor = (row: ITimeLogTableRow): string => {
    if (project?.contract_type === CONTRACT_TYPE.PRORATED_FIXED_PRICE) {
      return Number(row.daily_rate) === 0
        ? palette.warning[600]
        : (neutrals[700] as string);
    }

    if (
      [CONTRACT_TYPE.MIXED_PRICE, CONTRACT_TYPE.TIME_AND_MATERIAL].includes(
        project?.contract_type
      )
    ) {
      return Number(row.hourly_rate) === 0
        ? palette.warning[600]
        : (neutrals[700] as string);
    }

    return neutrals[700] as string;
  };

  const dates: Date[] = useMemo(
    () =>
      Array(daysInMonth)
        .fill(1)
        .map((_, index) => new Date(`${year}-${month}-${index + 1}`)),
    [daysInMonth, year, month]
  );

  useEffect(() => {
    fetchData();
  }, [report.id]);

  async function fetchData() {
    setLoading(true);
    const response: AxiosResponse<{ time_logs: ITimeLogTableRow[] }> =
      await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/project/report-timelogs`,
        {
          params: { id },
        }
      );

    const preparedData = (response.data.time_logs || []).map((item) => ({
      ...item,
      task_list: normalizeLogPerDay(item.task_list),
    }));

    preparedData.sort((a, b) => a.user_name.localeCompare(b.user_name));

    setData(preparedData);
    setLoading(false);
  }

  function normalizeLogPerDay(logs: ILogItem[]): ILogItem[] {
    const defaultValue: ILogItem = {
      date: '',
      hours: null,
      amount_per_day: null,
      approved: true,
    };
    const result = dates.map((date) => ({
      ...defaultValue,
      date: date.toString(),
    }));
    logs.forEach((day) => {
      const dateStr = day.date.match(/^(\d{4}-\d{2}-\d{2})/)?.[0];
      if (dateStr) {
        const index: number = Number(new Date(dateStr).getDate()) - 1;
        result[index] = day;
      }
    });
    return result;
  }

  return (
    <>
      <StyledFilterWrapper>
        <ExportCSVButton
          loading={exportLoading}
          onClick={handleExportCsv}
          disabled={loading || data?.length === 0}
        >
          Download CSV
        </ExportCSVButton>
      </StyledFilterWrapper>
      <TableContainer sx={{ maxHeight: 380 }}>
        {loading ? (
          <Box sx={{ textAlign: 'center' }}>
            <img src={loaderGif} alt="Data loading" />
          </Box>
        ) : (
          <Table stickyHeader aria-label="Time logs table">
            <TableHead>
              <TableRow>
                <TimeTableHeadCell
                  sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 9,
                    backgroundColor: neutrals.A200,
                  }}
                >
                  <Stack direction="column">
                    <span>Name</span>
                    <span>Rate</span>
                  </Stack>
                </TimeTableHeadCell>
                {dates?.map((date) => (
                  <TimeTableHeadCell
                    key={date.toString()}
                    $borderRight={dayjs(date).day() === 0}
                  >
                    <Stack direction="column">
                      <span>{date.getDate()}</span>
                      <span>{dayjs(date).format('ddd')}</span>
                    </Stack>
                  </TimeTableHeadCell>
                ))}
                <TimeTableHeadCell
                  sx={{
                    position: 'sticky',
                    right: 0,
                    zIndex: 9,
                    backgroundColor: neutrals.A200,
                    maxWidth: 'none !important',
                  }}
                >
                  <Stack direction="column">
                    <span>Total hours</span>
                    <span>Total Fee</span>
                  </Stack>
                </TimeTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.length ? (
                <>
                  <TableRow
                    sx={{
                      '.MuiTableCell-root': { borderBottom: 'none !important' },
                    }}
                  >
                    <StyledTableCell
                      colSpan={14}
                      sx={{
                        textAlign: 'center',
                        fontSize: '0.75rem !important',
                        fontWeight: '300 !important',
                        color: `${neutrals[500]} !important`,
                      }}
                    >
                      Time has not been logged for this month yet.
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '.MuiTableCell-root': { borderBottom: 'none !important' },
                    }}
                  >
                    <StyledTableCell colSpan={14} />
                  </TableRow>
                </>
              ) : (
                data.map((row) => (
                  <TableRow key={row.user_id}>
                    <TimeTableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: neutrals.A200,
                      }}
                    >
                      <Stack direction="column">
                        <span>{row.user_name}</span>
                        <StyledTotalPerDay $color={getRateDetailsColor(row)}>
                          {getRateDetails(row)}
                        </StyledTotalPerDay>
                      </Stack>
                    </TimeTableCell>
                    {row.task_list.map(
                      ({ date, hours, approved, amount_per_day }) => (
                        <TimeTableCell
                          key={`${row.user_id}-${date}`}
                          $warning={!approved}
                          $borderRight={dayjs(date).day() === 0}
                        >
                          {hours !== 0 &&
                            hours !== null &&
                            amount_per_day !== null && (
                              <Stack
                                title={approved ? '' : 'Time is not approved'}
                                direction="column"
                              >
                                <span>{formatHours(hours)} h</span>
                                <StyledTotalPerDay
                                  $color={getRateDetailsColor(row)}
                                >
                                  {oneDigitCurrencyFormatter(
                                    Number(amount_per_day || 0),
                                    currency
                                  )}
                                </StyledTotalPerDay>
                              </Stack>
                            )}
                        </TimeTableCell>
                      )
                    )}
                    <TimeTableCell
                      sx={{
                        position: 'sticky',
                        right: '-1px',
                        backgroundColor: neutrals.A200,
                      }}
                      $warning={Boolean(
                        row.task_list.find(({ approved }) => !approved)
                      )}
                    >
                      <Stack
                        direction="column"
                        title={
                          !row.task_list.find(({ approved }) => !approved)
                            ? ''
                            : 'Time is not approved'
                        }
                      >
                        <span>{formatHours(row.total_hours ?? 0)} h</span>
                        <StyledTotalPerDay $color={getRateDetailsColor(row)}>
                          {oneDigitCurrencyFormatter(
                            Number(row.total_fee),
                            currency
                          )}
                        </StyledTotalPerDay>
                      </Stack>
                    </TimeTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default TimeLogTable;
