import React, {
  ChangeEvent,
  FormEvent,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { cloneDeep } from 'lodash-es';
import { validEmail } from 'utils/helpers/formValidators';
import { Form, StyledAuthTextField } from 'pages/AuthPage/AuthPage.styles';
import Link from 'atoms/Link/Link';
import { palette } from 'utils/styles/variables';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { login, setError } from 'store/user/userSlice';
import PasswordTextField from 'atoms/PasswordTextField/PasswordTextField';
import { StyledActionsBlock, StyledContactText } from './SigninForm.styles';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

const SigninForm = () => {
  const { width } = useWindowDimensions();

  const [form, setForm] = useState({
    email: { value: '', error: '' },
    password: { value: '', error: '' },
    rememberMe: false,
  });
  const { error: reduxError, loading } = useBaoSelector((state) => state.user);
  const dispatch = useBaoDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const timeoutRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    const rememberUser = localStorage.getItem('rememberUser');
    const remember = localStorage.getItem('rememberMe');

    if (typeof rememberUser === 'string' && remember) {
      setForm((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          value: rememberUser,
        },
      }));

      setForm((prevState) => ({
        ...prevState,
        rememberMe: true,
      }));
    }
  }, []);

  useEffect(() => {
    switch (reduxError) {
      case 'email_not_found':
        setForm((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            error: `We couldn't find this email`,
          },
        }));
        break;
      case 'wrong_password':
        setForm((prevState) => ({
          ...prevState,
          password: {
            ...prevState.password,
            error: `Please write a correct password or click 'forgot-password' and follow the steps.`,
          },
        }));
        break;
      default:
        break;
    }
  }, [reduxError]);

  useEffect(
    () => {
      clearInterval(timeoutRef.current);

      if (reduxError && reduxError.indexOf('not yet expired') >= 0) {
        dispatch(setError(''));
        navigate('/verification');
        return () => clearInterval(timeoutRef.current);
      }

      if (reduxError && reduxError.indexOf('blocked') === -1) {
        return () => clearInterval(timeoutRef.current);
      }

      const blockedTime = checkIfUserBlocked(); // returns ms until blocked time.

      if (!blockedTime) {
        localStorage.removeItem('blocked');
        return () => clearInterval(timeoutRef.current);
      }

      if (!reduxError) {
        const blockedInfo = JSON.parse(localStorage.getItem('blocked') || '{}');
        dispatch(setError(blockedInfo.message));
      }

      timeoutRef.current = setInterval(() => {
        if (!checkIfUserBlocked()) {
          dispatch(setError(''));
          clearInterval(timeoutRef.current);
          localStorage.removeItem('blocked');
        }
      }, 1000);

      return () => clearInterval(timeoutRef.current);
    },
    // eslint-disable-next-line
    [reduxError]
  );

  const handleSubmit = async (e: FormEvent) => {
    localStorage.setItem('rememberMe', form.rememberMe ? 'true' : '');
    localStorage.setItem(
      'rememberUser',
      form.rememberMe ? form.email.value : ''
    );

    e.preventDefault();
    // clear errors
    dispatch(setError(''));
    const updatedForm = cloneDeep(form);
    if (!validEmail(form.email.value)) {
      updatedForm.email.error = 'Please enter valid email address!';
    }
    if (!form.password.value) {
      updatedForm.password.error = 'Please enter your password';
    }
    // if we have an error don't continue submitting form
    if (
      Boolean(updatedForm.email.error) ||
      Boolean(updatedForm.password.error)
    ) {
      return setForm(updatedForm);
    }
    const response = await dispatch(
      login({
        email: form.email.value,
        password: form.password.value,
        rememberMe: form.rememberMe,
      })
    );

    if (response.type.indexOf('rejected') !== -1) {
      return null;
    }

    return navigate(`/verification${search}`);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    let { error } = form.email;
    if (validEmail(newEmail)) {
      error = '';
    }
    setForm((prevState) => ({
      ...prevState,
      email: { ...prevState.email, value: newEmail, error },
    }));
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    let { error } = form.password;
    if (newPassword) {
      error = '';
    }
    setForm((prevState) => ({
      ...prevState,
      password: { ...prevState.password, value: newPassword, error },
    }));
  };

  // const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setForm((prevState) => ({
  //     ...prevState,
  //     rememberMe: e.target.checked,
  //   }));
  // };

  function checkIfUserBlocked(): number {
    const blockedInfo = JSON.parse(localStorage.getItem('blocked') || '{}');

    if (!blockedInfo.message) return 0;

    const diff = +blockedInfo.timestamp - +new Date() / 1000;

    dispatch(setError(blockedInfo.message));

    return diff > 0 ? diff : 0;
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {reduxError && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {reduxError.replaceAll('_', ' ')}
        </Alert>
      )}
      <Stack direction="column" gap="8px">
        <StyledAuthTextField
          autoComplete="email"
          fullWidth
          variant="outlined"
          label="Email"
          margin="normal"
          type="email"
          value={form.email.value}
          error={!!form.email.error}
          helperText={form.email.error}
          onChange={handleEmailChange}
          InputLabelProps={{ shrink: true }}
        />
        <PasswordTextField
          autoComplete="current-password"
          fullWidth
          variant="outlined"
          label="Password"
          margin="normal"
          error={!!form.password.error}
          helperText={form.password.error}
          onChange={handlePasswordChange}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 0 }}
        />
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        // sx={{ mb: width < 600 ? 0 : '16px' }}
        mb={width < 600 ? 0 : '16px'}
      >
        {/* {width < 600 ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.rememberMe}
                  onChange={handleRememberMeChange}
                  sx={{
                    padding: '0 9px',
                  }}
                  icon={<UnCheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              }
              sx={{
                ml: 0,
                span: {
                  fontWeight: '600 !important',
                  fontSize: '0.75rem !important',
                  lineHeight: '22px',
                  color: `${neutrals[500]} !important`,
                },
              }}
              label="Remember me"
              labelPlacement="start"
            />
          </FormGroup>
        ) : (
          <span />
        )} */}
        <span />
        <Link
          to="/forgot-password"
          $fontWeight={500}
          $lineHeight="18px"
          $fontSize="0.75rem"
        >
          Forgot password
        </Link>
      </Stack>
      <LoadingButton
        variant="contained"
        type="submit"
        sx={{
          margin: '16px 0',
          lineHeight: '20px',
          borderRadius: '3px',
          marginTop: width < 600 ? '5vh' : 0,
        }}
        fullWidth
        loading={loading}
      >
        LOG IN
      </LoadingButton>
      <StyledActionsBlock>
        <Stack>
          <span>Not registered yet?</span>
          <Link to="/request-account" $color={palette.primary.main}>
            Request account
          </Link>
        </Stack>
        <StyledContactText>
          For support, contact us at{' '}
          <a href="mailto:zconnect.support@zazmic.com">
            zconnect.support@zazmic.com
          </a>
        </StyledContactText>
        {/* <Stack>
          <span>Need help?</span>
          <Link to="/contact-us" $color={palette.primary.main}>
            Contact us
          </Link>
        </Stack> */}
      </StyledActionsBlock>
    </Form>
  );
};

export default SigninForm;
