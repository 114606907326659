import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Stack,
} from '@mui/material';
import { ErrorTableCell, ErrorTableHeadCell } from './ReportErrorTable.styles';
import { ErrorStateItem, Report } from 'organisms/CloudOpsReportAdmin/types';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import loaderGif from 'assets/images/loaderGif.gif';

const ReportErrorTable: FC<{ report: Report }> = ({ report }) => {
  const [loading] = useState<boolean>(false);

  const errors = report?.error_labels_links;

  if (!errors?.length) return <span />;

  return (
    <TableContainer sx={{ maxHeight: 300 }}>
      {loading ? (
        <Box sx={{ textAlign: 'center' }}>
          <img src={loaderGif} alt="Data loading" />
        </Box>
      ) : (
        <Table stickyHeader aria-label="Time logs table">
          <TableHead>
            <TableRow>
              <ErrorTableHeadCell>Field name</ErrorTableHeadCell>
              <ErrorTableHeadCell>Check results</ErrorTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors?.map(({ labels, links, error_states }) => (
              <TableRow key={labels || ''}>
                <ErrorTableCell>
                  <Stack>
                    {labels}
                    <Link to={links} target="_blank" rel="noopener noreferrer">
                      View in Zoho Projects
                    </Link>
                  </Stack>
                </ErrorTableCell>
                <ErrorTableCell>
                  {error_states?.length ? (
                    <Stack>
                      {error_states.map((item: ErrorStateItem) => (
                        <span key={item.property}>{item.message}</span>
                      ))}
                    </Stack>
                  ) : (
                    <CheckmarkIcon />
                  )}
                </ErrorTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ReportErrorTable;
